const apiPath = 'https://moalytics-api.wiseobjects.com';
const pathWebapp = 'https://monalytics-app.wiseobjects.com';

/* const apiPath = 'http://localhost:3939';
const pathWebapp = 'http://localhost:9988';  */

const appDeclarations = {
    connectedUser: 'connected_____use235',
    connectedCompany: 'connected_company__001',
    currentLang: 'current_language',
    connectionStatus: 'connect_status',
    apiRootTest: `${apiPath}/api/`,
    apiUrlTest: apiPath,
    apiPath: apiPath,
    webAppPath: pathWebapp,
    companyResourcesPath: `${apiPath}/cloud_data/company_res`,
    profilesPath: `${apiPath}/cloud_data/profiles`,
    campaignRes: `${apiPath}/cloud_data/campaigns`,
    influencerProfilePath: `${apiPath}/cloud_data/influencerprofiles`,
    filesResources: `${apiPath}/cloud_data/file_resources`,

    userAccessKey: 'user_access_key',


    company_code: 'WBTMONALTCSAPP003-UPMANGT',
    currentYearbook: 'current_year_book',


    currentDefaultSmsAcct: 'current_def_sms_acct',
    fileSizeallowed: 'file_size_allowed',
    itemsPerPage: 'items_per_page',
    currentDevise: 'current_devise',
    currentBranch: 'current_branch',
    userEmail: 'user_email',
    userPassword: 'userPassword',
    cartZoneArea: 'cart___zone_area____2363',
    mainPhoneNbr: '250 788 510 589',
    waitingEmailForPw: 'awaiting_____email_for_pw____x25'

}
export default appDeclarations;