import React, { useEffect, useMemo, useState } from "react";
import dateAndTime from "date-and-time";
import ApexCharts from "react-apexcharts";

import getLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import appDecl from "src/utils/declarations";

import { ReactComponent as FaceBookSvg } from "src/assets/images/svg/icons/facebook.svg";
import { ReactComponent as TwitterSvg } from "src/assets/images/svg/icons/twitter.svg";
import { ReactComponent as TiktokSvg } from "src/assets/images/svg/icons/tiktok.svg";
import { ReactComponent as InstagramSvg } from "src/assets/images/svg/icons/instagram.svg";
import { ReactComponent as LinkedinSvg } from "src/assets/images/svg/icons/linkedin.svg";
import { ReactComponent as SnapchatSvg } from "src/assets/images/svg/icons/snapchat.svg";

import hashTagImage from "src/assets/images/hashtag.png";

import NetServices from "src/utils/netservices";
import { BootstrapInput } from "src/components/WiseModalTextInput";
import { Button, DropdownMenu } from "@radix-ui/themes";
import { CalendarIcon, DropdownMenuIcon } from "@radix-ui/react-icons";
import {
  IMonalytCampaign,
  IPostWithTagsRecord,
} from "src/models/monalytics_models";
import { useNavigate } from "react-router-dom";
import logoImg from "src/assets/images/campaign_logo.png";
import fcountries from "src/assets/resources/country_tel_codes.json";
import {
  IAnalysisChartCard,
  IAnalysisComponentsArea,
  IAnalysisMensionCompareCahrt,
  IAnalysisToolCard,
  IChannelTagCover,
  IChartApex,
  ICountry,
  ICountyWithCtr,
  IDateMention,
  IDateRangeStartEnd,
  IMonthTagValue,
  IStrongHashTag,
  ITagMention,
  IValPercent,
} from "src/models/smarttypes";
import COLORS from "src/utils/colors";

const languages = getLang();
const appFxs = generalFxs();
const lstMonths = appFxs.wdMonthsLng;

const todayDate = new Date();
const todayNextMonth = dateAndTime.addMonths(todayDate, 1);
const countries = fcountries as ICountyWithCtr;

const AnalysisPage = () => {
  document.title = `${languages.analysis} | Monalytics`;

  const [poplating, setPopulating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState("");
  const [searchType, setSearchType] = useState(0);
  const [selectedTag, setSelectedTag] = useState("");
  const [lstCampaigns, setLstCampaigns] = useState<IMonalytCampaign[]>([]);
  const [selectedCamp, setSelctedCamp] = useState<IMonalytCampaign | null>(
    null
  );
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayNextMonth);
  const [lstRecords, setLstRecords] = useState<IPostWithTagsRecord[]>([]);

  const navigate = useNavigate();

  const [selectedPeriod, setSelectedPeriod] = useState(
    todayDate.getMonth() + 1
  );
  const monthName = useMemo(() => {
    return lstMonths[selectedPeriod - 1];
  }, [selectedPeriod]);

  const populateData = async () => {
    setLoading(true);

    const oOper = await NetServices.requestGet(
      `monalytics/getlistofcampaignbyperiod/${selectedPeriod}`,
      false
    );

    if (!oOper.bReturn) {
      const bAsk = await appFxs.showAlertConfirm(oOper.msgTitle, oOper.msgBody);
      if (bAsk.isDismissed) {
        navigate("/app/dashboard");
      }
      return;
    }

    setLstCampaigns(oOper.lstCampaigns!);
    setLoading(false);
  };

  const onChangeMonth = (iMonth: number) => {
    setSelectedPeriod(iMonth);
    setSelctedCamp(null);
    setSelectedTag("");
    setCountry("");
  };

  const onChangeSearchType = (iType: number) => {
    setSearchType(iType);
    setSelctedCamp(null);
    setSelectedTag("");
    setCountry("");
  };

  const isAnalysisReady = useMemo(() => {
    const bDate = endDate.getTime() >= startDate.getTime();
    return bDate && selectedTag.length > 0;
  }, [selectedTag, startDate, endDate]);

  const countyObject: ICountry | null = useMemo(() => {
    if (!country) return null;
    const ctry = countries.Countries.find((o) => o.code === country);
    return ctry ?? null;
  }, [country]);

  const startAnalysis = async () => {
    setPopulating(true);
    const oOper = await NetServices.requestPost(
      "socialaccess/getallpostsbytagforperiod",
      {
        socialDataRec: {
          country,
          endDate,
          startDate,
          tag: selectedTag,
        },
      },
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setPopulating(false);
      return;
    }

    setPopulating(false);
    setLstRecords(oOper.listPostsForAnalysis!);
  };

  useEffect(() => {
    populateData();
  }, [selectedPeriod]);

  return (
    <div className="flex flex-col">
      <div className="flex items-center flex-wrap space-y-2 space-x-3 px-3 py-2">
        {/* Month */}
        <div className="mt-2">
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <Button>
                <CalendarIcon /> {monthName} <DropdownMenuIcon />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              {lstMonths.map((o, idx) => (
                <DropdownMenu.Item
                  className="cursor-pointer"
                  onClick={() => onChangeMonth(idx + 1)}
                  key={idx}
                >
                  <span
                    data-active={idx === selectedPeriod - 1}
                    className="data-[active=true]:font-bold"
                  >
                    {o}
                  </span>
                </DropdownMenu.Item>
              ))}
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>

        {/* Search type */}
        <div>
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <Button className="w-[120px]" color="amber">
                {searchType === 0 ? languages.campaign : languages.hashtag}
                &nbsp;
                <i className="fa fa-chevron-down text-xs"></i>
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              <DropdownMenu.Item
                className="cursor-pointer"
                onClick={() => onChangeSearchType(0)}
              >
                <div className="flex items-center space-x-2">
                  <i className="fa fa-image"></i>
                  <span>{languages.byCampaign}</span>
                </div>
              </DropdownMenu.Item>
              <DropdownMenu.Item
                className="cursor-pointer"
                onClick={() => onChangeSearchType(1)}
              >
                <div className="flex items-center space-x-2">
                  <i className="fa fa-tags"></i>
                  <span>{languages.byHashtag}</span>
                </div>
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>

        {/* Hashtag field */}
        {searchType === 1 && (
          <div>
            <BootstrapInput
              fieldName="selectedTag"
              fldType="text"
              label=""
              value={selectedTag}
              placeholder={languages.hashtag}
              onChange={(f, v) => setSelectedTag(String(v))}
            />
          </div>
        )}

        {/* Campaigns Hashtag field */}
        {searchType === 0 && (
          <div>
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <Button className="min-w-[120px]" color="cyan">
                  {!selectedCamp && (
                    <div className="flex items-center">
                      {languages.noSelection}
                    </div>
                  )}
                  {selectedCamp && (
                    <div className="flex items-center space-x-3">
                      <img
                        className="inline-block w-6 h-6 rounded-full"
                        alt=""
                        src={
                          selectedCamp.campaignLogo
                            ? `${appDecl.campaignRes}/${
                                selectedCamp!.companyKey
                              }/${selectedCamp!.campaignLogo}`
                            : logoImg
                        }
                      />
                      <span>
                        {appFxs.shortenText(selectedCamp.title, 15, true)}
                      </span>
                    </div>
                  )}
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                {lstCampaigns.map((o, idx) => (
                  <DropdownMenu.Item
                    className="cursor-pointer"
                    onClick={() => setSelctedCamp(o)}
                    key={idx}
                  >
                    <div className="flex items-center space-x-2">
                      <img
                        className="inline-block w-5 h-5 rounded-full"
                        alt=""
                        src={
                          o.campaignLogo
                            ? `${appDecl.campaignRes}/${o!.companyKey}/${
                                o!.campaignLogo
                              }`
                            : logoImg
                        }
                      />
                      <span>{o.title}</span>
                    </div>
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </div>
        )}

        {/* Tags from campaign */}
        {searchType === 0 && selectedCamp && (
          <div>
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <Button className="min-w-[120px]" color="cyan">
                  {!selectedTag && (
                    <div className="flex items-center">{languages.noTag}</div>
                  )}
                  {selectedTag && (
                    <div className="flex items-center space-x-1">
                      <span>#{selectedTag}</span>
                    </div>
                  )}
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                {selectedCamp.hashtag.map((o, idx) => (
                  <DropdownMenu.Item
                    className="cursor-pointer"
                    onClick={() => setSelectedTag(o)}
                    key={idx}
                  >
                    <div className="flex items-center">
                      <span>#{o}</span>
                    </div>
                  </DropdownMenu.Item>
                ))}
                {selectedTag && (
                  <DropdownMenu.Item
                    className="cursor-pointer bg-blue-500 text-white font-semibold"
                    onClick={() => setSelectedTag("")}
                  >
                    <div className="flex items-center">
                      <span>{languages.reset}</span>
                    </div>
                  </DropdownMenu.Item>
                )}
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </div>
        )}

        {/* countries */}
        <div>
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <Button className="min-w-[120px]" color="cyan">
                {!countyObject && (
                  <div className="flex items-center">{languages.noCountry}</div>
                )}
                {countyObject && (
                  <div className="flex items-center space-x-1">
                    <i className="fa fa-flag"></i>{" "}
                    <span>{countyObject.name}</span>
                  </div>
                )}
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              {countries.Countries.map((o, idx) => (
                <DropdownMenu.Item
                  className="cursor-pointer"
                  onClick={() => setCountry(o.code)}
                  key={idx}
                >
                  <div className="flex items-center">
                    <span>{o.name}</span>
                  </div>
                </DropdownMenu.Item>
              ))}
              {selectedTag && (
                <DropdownMenu.Item
                  className="cursor-pointer bg-blue-500 text-white font-semibold"
                  onClick={() => setCountry("")}
                >
                  <div className="flex items-center">
                    <span>{languages.reset}</span>
                  </div>
                </DropdownMenu.Item>
              )}
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>

        {/* Start date */}
        <div>
          <BootstrapInput
            fieldName="startDate"
            value={appFxs.getDateForInput(startDate)}
            fldType="date"
            label=""
            onChange={(f, v) => setStartDate(new Date(v))}
          />
        </div>

        {/* End date */}
        <div>
          <BootstrapInput
            fieldName="endDate"
            value={appFxs.getDateForInput(endDate)}
            fldType="date"
            label=""
            onChange={(f, v) => setEndDate(new Date(v))}
          />
        </div>

        <div>
          <Button onClick={() => startAnalysis()} disabled={!isAnalysisReady}>
            {languages.launchAnalys}
          </Button>
        </div>
      </div>
      <AnalysisComponentsArea
        busy={poplating || loading}
        lst={lstRecords}
        dateFrom={startDate}
        dateTo={endDate}
        tag={selectedTag}
      />
    </div>
  );
};

export default AnalysisPage;

const AnalysisComponentsArea = ({
  busy,
  lst,
  dateFrom,
  dateTo,
  tag,
}: IAnalysisComponentsArea) => {
  return (
    <div className="flex flex-col">
      {/* Analysis */}
      <div className="flex flex-wrap items-stretch">
        {/* Mentions */}
        <div className="w-full md:w-1/2">
          <div className="flex flex-col">
            <div className="flex justify-between items-center border-b p-3">
              <h3 className="font-semibold text-lg">{languages.mentions}</h3>
            </div>
            <div className="flex flex-wrap">
              <AnalysisToolCard busy={busy} lst={lst} type="likes" />
              <AnalysisToolCard busy={busy} lst={lst} type="positive" />
              <AnalysisToolCard busy={busy} lst={lst} type="negative" />
              <AnalysisMensionCompareCahrt
                busy={busy}
                dateFrom={dateFrom}
                dateTo={dateTo}
                tag={tag}
                lst={lst}
              />
            </div>
          </div>
        </div>
        {/* Charts */}
        <div className="w-full md:w-1/2">
          <div className="p-2">
            <TagAnalysisYearlyComp busy={busy} tag={tag} />
          </div>
        </div>
      </div>
      {/* Channels / Tags */}
      <div className="flex flex-wrap items-stretch mt-4">
        {/* Channels */}
        <div className="w-full md:w-1/2">
          <div className="p-3">
            <ChannelsRelatedToTagComp busy={busy} lst={lst} />
          </div>
        </div>
        {/* Tags */}
        <div className="w-full md:w-1/2">
          <div className="p-3">
            <StrongestTagsCoverComp
              dateRange={{
                endDate: dateTo,
                startDate: dateFrom,
              }}
              busy={busy}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const AnalysisToolCard = ({ lst, busy, type }: IAnalysisToolCard) => {
  const label =
    type === "likes"
      ? languages.mentions
      : type === "negative"
      ? languages.negative
      : type === "positive"
      ? languages.positive
      : languages.mentions;

  const mentions =
    lst.length === 0 ? 0 : lst.reduce((a, c) => a + (c.likes + c.dislikes), 0);
  const postives = lst.length === 0 ? 0 : lst.reduce((a, c) => a + c.likes, 0);
  const negatives =
    lst.length === 0 ? 0 : lst.reduce((a, c) => a + c.dislikes, 0);

  const sitNbr =
    type === "likes"
      ? mentions
      : type === "negative"
      ? negatives
      : type === "positive"
      ? postives
      : mentions;

  return (
    <div className="w-full md:w-1/3 relative min-h-[169.6px]">
      <div
        data-vis={!busy}
        className="absolute w-full h-full data-[vis=true]:hidden  flex justify-center items-center z-10 bg-white"
      >
        <i className="fa fa-spin fa-spinner fa-3x"></i>
      </div>
      <div className="m-3 border border-blue-400 rounded-2xl min-h-7 p-3 bg-white shadow">
        <h3 className="uppercase text-neutral-500 text-sm font-semibold mb-6">
          {label}
        </h3>
        <p className="text-[1.5rem] font-extrabold mb-6">
          {appFxs.formatNumberReducer(sitNbr)}
        </p>
      </div>
    </div>
  );
};

const AnalysisMensionCompareCahrt = ({
  dateFrom,
  dateTo,
  tag,
  busy,
  lst,
}: IAnalysisMensionCompareCahrt) => {
  return (
    <>
      <AnalysisChartCard busy={busy} type="likes" lst={lst} />
      <AnalysisChartCard busy={busy} type="positive" lst={lst} />
      <AnalysisChartCard busy={busy} type="negative" lst={lst} />
    </>
  );
};

const AnalysisChartCard = ({ busy, type, lst }: IAnalysisChartCard) => {
  const allMentions: IDateMention[] =
    lst.length === 0
      ? []
      : Object.values(
          lst.reduce((acc, record) => {
            if (!acc[record.postDate.toString()]) {
              acc[record.postDate.toString()] = {
                date: record.postDate,
                mentions: 0,
              };
            }
            const allMentions = record.likes + record.dislikes;
            const positives = record.likes;
            const negatives = record.dislikes;

            const fData =
              type === "likes"
                ? allMentions
                : type === "positive"
                ? positives
                : type === "negative"
                ? negatives
                : allMentions;

            acc[record.postDate.toString()].mentions += fData;
            return acc;
          }, {} as Record<string, IDateMention>)
        );

  const figures = (): IValPercent => {
    const oMents = allMentions;

    let fVal = 0;
    let fPer = 0;

    if (oMents.length == 0) {
      fVal = 0;
      fPer = 0;
    } else if (oMents.length === 1) {
      fVal = oMents[0].mentions;
      fPer = 100;
    } else if (oMents.length > 1) {
      const oLast = oMents[oMents.length - 1];
      const oPrev = oMents[oMents.length - 2];

      fVal = oLast.mentions - oPrev.mentions;
      fPer = appFxs.calculateGrowthPercent(oPrev.mentions, oLast.mentions);
    }

    return {
      val: fVal,
      perc: fPer,
    };
  };

  const chartOptions: IChartApex = {
    options: {
      chart: {
        id: "apexchart-example",
        toolbar: {
          show: false,
        },
        height: 45,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      tooltip: {
        fixed: {
          enabled: false,
        },
        x: {
          show: false,
        },
        marker: {
          show: false,
        },
      },
      xaxis: {
        categories: [],
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
        show: false,
      },
    },
    series: [
      {
        data: allMentions.map((o) => o.mentions),
        type: "area",
      },
    ],
  };

  return (
    <div className="w-full md:w-1/3 minh-h-[203.55px] relative">
      <div
        data-vis={!busy}
        className="absolute w-full h-full data-[vis=true]:hidden  flex justify-center items-center z-10 bg-white"
      >
        <i className="fa fa-spin fa-spinner fa-3x"></i>
      </div>
      <div className="m-3 border border-blue-400 rounded-2xl min-h-7 p-3 bg-white shadow">
        <h3 className="uppercase mb-1">
          <span className="text-2xl text-black font-semibold">
            {appFxs.formatNumberReducer(figures().val)}
          </span>
          &nbsp;&nbsp;
          <span
            data-or={figures().perc > 0 ? 2 : figures().perc === 0 ? 0 : 1}
            className="text-sm text-red-700 data-[or=2]:text-teal-700 data-[or=0]:text-black font-semibold"
          >
            ({figures().perc.toFixed(1)}%)
          </span>
        </h3>
        <div className="w-full">
          <ApexCharts
            series={chartOptions.series}
            options={chartOptions.options}
            type="area"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
};

const TagAnalysisYearlyComp = ({
  tag,
  busy,
}: {
  tag: string;
  busy: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [chartOptions, setChartOptions] = useState<IChartApex>({
    options: {
      chart: {
        background: "transparent",
        stacked: false,
        id: "apex-tag-year-activity",
        toolbar: {
          show: false,
        },
      },
      fill: { opacity: 1, type: "solid" },
      grid: {
        strokeDashArray: 2,
        xaxis: { lines: { show: false } },
        yaxis: { lines: { show: false } },
      },
      legend: { show: false },
      plotOptions: { bar: { columnWidth: "15px", borderRadius: 6 } },
      stroke: { colors: ["transparent"], show: true, width: 2 },
      xaxis: {
        axisBorder: { color: COLORS.airForceBlue, show: false },
        axisTicks: { color: COLORS.airForceBlue, show: false },
        categories: appFxs.wdMonthsShrt.map((o) => o.toUpperCase()),
        labels: { offsetY: 5, style: { colors: COLORS.airForceBlue } },
      },
      yaxis: {
        labels: {
          formatter: (value) => (value > 0 ? `${value}` : `${value}`),
          offsetX: -10,
          style: { colors: COLORS.airForceBlue },
        },
      },
    },
    series: [
      {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        type: "bar",
      },
    ],
  });

  const populateData = async () => {
    if (!tag || busy) return;
    setLoading(true);
    setErrorMessage(null);

    const oOper = await NetServices.requestGet(
      `socialaccess/gettagactivityforspecificyear/${tag}`
    );

    if (!oOper.bReturn) {
      setErrorMessage(oOper.msgBody);
      setLoading(false);
      return;
    }

    const lstFinals = oOper.listTagsYearAggregated!;
    const finalRecs: IMonthTagValue[] = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
    ].map((o) => {
      const oRec = lstFinals.find((x) => x.month === o);

      return {
        mentions: !oRec ? 0 : oRec.likes - oRec.dislikes,
        month: o,
      };
    });

    setChartOptions({
      ...chartOptions,
      series: [
        {
          data: finalRecs.map((o) => o.mentions),
          type: "bar",
        },
      ],
    });

    setErrorMessage(oOper.msgBody);
    setLoading(false);
  };

  useEffect(() => {
    populateData();
  }, [tag, busy]);

  return (
    <div className="flex flex-col border border-blue-400 rounded-2xl min-h-7 p-3 bg-white shadow">
      <div className="flex justify-between items-center border-b py-2">
        <h3 className="font-semibold text-lg">{languages.tagActivity}</h3>
      </div>
      <div className="flex-1 relative">
        <div
          data-vis={!busy}
          className="absolute w-full h-full z-10 bg-white data-[vis=true]:hidden flex justify-center items-center"
        >
          <i className="fa fa spin fa-spinner fa-3x"></i>
        </div>
        <ApexCharts
          series={chartOptions.series}
          options={chartOptions.options}
          type="bar"
          width="100%"
        />
      </div>
    </div>
  );
};

interface IChannelsRelatedToTagComp {
  lst: IPostWithTagsRecord[];
  busy: boolean;
}
const ChannelsRelatedToTagComp = ({ busy, lst }: IChannelsRelatedToTagComp) => {
  const oTotal = lst.reduce((a, c) => a + (c.likes + c.dislikes), 0);

  const lstChannels: IChannelTagCover[] = useMemo(() => {
    const flst = Object.values(
      lst.reduce((acc, record) => {
        if (!acc[record.channel]) {
          acc[record.channel] = {
            total: oTotal,
            type: record.channel,
            value: 0,
          };
        }
        acc[record.channel].value += record.likes + record.dislikes;
        return acc;
      }, {} as Record<string, IChannelTagCover>)
    );

    return flst;
  }, [lst]);

  return (
    <div className="flex flex-col border border-blue-400 rounded-2xl min-h-7 p-3 bg-white shadow">
      {/* Header */}
      <div className="flex justify-between items-center border-b py-2">
        <h3 className="font-semibold text-lg">{languages.channels}</h3>
      </div>
      {/* Body */}
      <div className="flex-1 relative min-h-[250px]">
        <div
          data-vis={!busy}
          className="absolute w-full h-full z-10 bg-white data-[vis=true]:hidden flex justify-center items-center"
        >
          <i className="fa fa spin fa-spinner fa-3x"></i>
        </div>
        <div className="flex flex-col py-3 space-y-3">
          {lstChannels.length > 0 && (
            <>
              {lstChannels.map((o, idx) => (
                <ChannelTagCoverComp key={idx} data={o} />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
interface IChannelTagCoverComp {
  data: IChannelTagCover;
}
const ChannelTagCoverComp = ({ data }: IChannelTagCoverComp) => {
  const fillPerc = (data.value / data.total) * 100;
  const CompIcon =
    data.type === "facebook"
      ? FaceBookSvg
      : data.type === "instagram"
      ? InstagramSvg
      : data.type === "linkedin"
      ? LinkedinSvg
      : data.type === "tiktok"
      ? TiktokSvg
      : data.type === "twitter"
      ? TwitterSvg
      : data.type === "snapchat"
      ? SnapchatSvg
      : FaceBookSvg;

  const CompTitle =
    data.type === "facebook"
      ? "Facebook"
      : data.type === "instagram"
      ? "Instagram"
      : data.type === "linkedin"
      ? "LinkedIn"
      : data.type === "tiktok"
      ? "TikTok"
      : data.type === "twitter"
      ? "Twitter"
      : data.type === "snapchat"
      ? "Snapchat"
      : "Facebook";

  const CompColor =
    data.type === "facebook"
      ? "#1877f2"
      : data.type === "instagram"
      ? "#ee6545"
      : data.type === "linkedin"
      ? "#0a66c2"
      : data.type === "tiktok"
      ? "#ef295b"
      : data.type === "twitter"
      ? "#00aced"
      : data.type === "snapchat"
      ? "#ffec06"
      : "#1877f2";
  return (
    <div className="flex items-center space-x-3">
      {/* logo */}
      <div className="flex justify-center items-center w-14 h-14">
        <CompIcon className="w-11 h-11" />
      </div>
      {/* channel */}
      <div className="flex-1 flex flex-col space-y-1">
        <p className="font-bold text-base">{CompTitle}</p>
        <div className="w-full rounded-lg relative bg-[#fce0e0] overflow-hidden h-[10px]">
          <div
            className="absolute left-0 h-full bg-[#ff923d]"
            style={{
              width: `${fillPerc}%`,
              backgroundColor: CompColor,
            }}
          ></div>
        </div>
      </div>
      {/* hits */}
      <div className="space-x-1">
        <div className="px-2 text-neutral-600 font-semibold w-[100px] text-end">
          {fillPerc.toFixed(2)}%
        </div>
      </div>
    </div>
  );
};

interface IStrongestTagsCoverComp {
  dateRange: IDateRangeStartEnd;
  busy: boolean;
}
const StrongestTagsCoverComp = ({
  dateRange,
  busy,
}: IStrongestTagsCoverComp) => {
  const [loading, setLoading] = useState(false);
  const [msgError, setMsgError] = useState<string | null>(null);
  const [lstTopTags, setLstTopTags] = useState<ITagMention[]>([]);

  const populateData = async () => {
    setLoading(true);
    setMsgError(null);

    const oOper = await NetServices.requestPost(
      "socialaccess/getstrongesttagbyperiod",
      {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      }
    );

    if (!oOper.bReturn) {
      setMsgError(oOper.msgBody);
      setLoading(false);
      return;
    }

    setLstTopTags(oOper.listTopHighTags!);

    setMsgError(null);
    setLoading(false);
  };


  const lstFormatedStongTags: IStrongHashTag[] = useMemo(() => {
    const fTotal =
      lstTopTags.length === 0
        ? 0
        : lstTopTags.reduce((a, c) => a + c.mentions, 0);

    const xFinal: IStrongHashTag[] = lstTopTags.map((fx) => {
      return {
        tagName: fx.tag,
        total: fTotal,
        value: fx.mentions,
      };
    });

    return xFinal;
  }, [lstTopTags]);

  useEffect(() => {
    populateData();
  }, [dateRange]);

  return (
    <div className="flex flex-col border border-blue-400 rounded-2xl min-h-7 p-3 bg-white shadow">
      {/* Header */}
      <div className="flex justify-between items-center border-b py-2">
        <div>
          <h3 className="font-semibold text-lg">
            {languages.strongestHashTags}
          </h3>
          <div className="flex items-center space-x-3">
            <span className="inline-block text-xs text-gray-500">
              {languages.from}:
            </span>
            <span className="inline-block text-xs">
              {appFxs.formatDate(dateRange.startDate)}
            </span>
            <span className="inline-block text-xs text-gray-500">
              {languages.to}:
            </span>
            <span className="inline-block text-xs">
              {appFxs.formatDate(dateRange.endDate)}
            </span>
          </div>
        </div>
      </div>
      {/* Body */}
      <div className="flex-1 relative min-h-[250px]">
        <div
          data-vis={!busy && !loading}
          className="absolute w-full h-full z-10 bg-white data-[vis=true]:hidden flex justify-center items-center"
        >
          <i className="fa fa spin fa-spinner fa-3x"></i>
        </div>
        <div className="flex flex-col py-3 space-y-3">
          {lstFormatedStongTags.map((o, idx) => (
            <StrongTagCoverComp key={idx} data={o} />
          ))}
        </div>
      </div>
    </div>
  );
};

interface IStrongTagCoverComp {
  data: IStrongHashTag;
}
const StrongTagCoverComp = ({ data }: IStrongTagCoverComp) => {
  const fillPerc = (data.value / data.total) * 100;

  return (
    <div className="flex items-center space-x-3">
      {/* logo */}
      <div className="flex justify-center items-center w-14 h-14">
        <img src={hashTagImage} className="w-11 h-11" />
      </div>
      {/* channel */}
      <div className="flex-1 flex flex-col space-y-1">
        <p className="font-bold text-base">{data.tagName}</p>
        <div className="w-full rounded-lg relative bg-[#d9fbeb] overflow-hidden h-[10px]">
          <div
            className="absolute left-0 h-full"
            style={{
              width: `${fillPerc}%`,
              backgroundColor: "#5de372",
            }}
          ></div>
        </div>
      </div>
      {/* hits */}
      <div className="space-x-1">
        <div className="px-2 text-neutral-600 font-semibold w-[100px] text-end">
          {fillPerc.toFixed(2)}%
        </div>
      </div>
    </div>
  );
};
