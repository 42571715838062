import React, { useEffect, useMemo, useState } from "react";
import dateAndTime from "date-and-time";

import getLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import appDecl from "src/utils/declarations";

import NetServices from "src/utils/netservices";
import { Button, DropdownMenu } from "@radix-ui/themes";
import { CalendarIcon, DropdownMenuIcon } from "@radix-ui/react-icons";
import {
  IMonalytCampaign,
  IMonalytCampaignInfl,
} from "src/models/monalytics_models";
import { Link } from "react-router-dom";
import fcountries from "src/assets/resources/country_tel_codes.json";
import {
  ICountyWithCtr,
  TDashboardTool,
} from "src/models/smarttypes";
import COLORS from "src/utils/colors";

const languages = getLang();
const appFxs = generalFxs();
const lstMonths = appFxs.wdMonthsLng;

const todayDate = new Date();
const todayNextMonth = dateAndTime.addMonths(todayDate, 1);
const countries = fcountries as ICountyWithCtr;
const currency = appFxs.getLocalStorageByKey(appDecl.currentDevise, "RWF");

const DashboardPage = () => {
  document.title = `${languages.dashboard} | Monalytics`;

  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [lstCampaigns, setLstCampaigns] = useState<IMonalytCampaign[]>([]);
  const [lstPrevCampaigns, setLstPrevCampaigns] = useState<IMonalytCampaign[]>([]);
  const [lstExpenses, setLstExpenses] = useState<IMonalytCampaignInfl[]>([]);
  const [lstPrevExpenses, setLstPrevExpenses] = useState<IMonalytCampaignInfl[]>([]);

  const [selectedPeriod, setSelectedPeriod] = useState(
    todayDate.getMonth() + 1
  );
  const monthName = useMemo(() => {
    return lstMonths[selectedPeriod - 1];
  }, [selectedPeriod]);

  const onChangeMonth = (iMonth: number) => {
    setSelectedPeriod(iMonth);
  };

  const populateData = async () => {
    setLoading(true);
    setErrorMsg(null);

    const oOper = await NetServices.requestGet(
      `dashboard/getbudgethistorybymonthdashboard/${selectedPeriod}`,
      false
    );

    if (!oOper.bReturn) {
      setErrorMsg(oOper.msgBody);
      setLoading(false);
      return;
    }

    setErrorMsg(null);
    setLstCampaigns(oOper.lstCampaigns!);
    setLstPrevCampaigns(oOper.lstPrevCampaigns!);
    setLstExpenses(oOper.lstHiredInflencers!);
    setLstPrevExpenses(oOper.lstPrevHiredInflencers!);
    setLoading(false);
  };

  useEffect(() => {
    populateData();
  }, [selectedPeriod]);

  return (
    <div className="flex flex-col h-full">
      {/* Header */}
      <div className="flex items-center justify-between py-3 px-3 border-b">
        <h3 className="text-lg font-semibold ">
          <span className="hidden">{languages.dashboard}</span>
        </h3>
        <div>
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <Button>
                <CalendarIcon /> {monthName} <DropdownMenuIcon />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              {lstMonths.map((o, idx) => (
                <DropdownMenu.Item
                  className="cursor-pointer"
                  onClick={() => onChangeMonth(idx + 1)}
                  key={idx}
                >
                  <span
                    data-active={idx === selectedPeriod - 1}
                    className="data-[active=true]:font-bold"
                  >
                    {o}
                  </span>
                </DropdownMenu.Item>
              ))}
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>
      </div>
      {/* Body */}
      <div className="bg-[#f8f9fc] px-4 py-5">
        {/* tools */}
        <DashboardToolWrapper
          errorMsg={errorMsg}
          loading={loading}
          campaigns={lstCampaigns}
          prevCamps={lstPrevCampaigns}
          expenses={lstExpenses}
          prevExpenses={lstPrevExpenses}
        />

        {/* Budget */}
        <div className="mt-6 mb-4">
          <LastTopBudgetCampaigns
            errorMsg={errorMsg}
            loading={loading}
            lstAll={lstCampaigns}
            onRefresh={() => populateData()}
            lstExpenses={lstExpenses}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;

interface IDashboardToolWrapper {
  loading: boolean;
  campaigns: IMonalytCampaign[];
  prevCamps: IMonalytCampaign[];
  expenses: IMonalytCampaignInfl[];
  prevExpenses: IMonalytCampaignInfl[];
  errorMsg: string | null;
}

const DashboardToolWrapper = ({
  errorMsg,
  loading,
  campaigns,
  prevCamps,
  expenses,
  prevExpenses,
}: IDashboardToolWrapper) => {
  const campNbr: number = useMemo(() => {
    if (errorMsg) return 0;
    return campaigns.length;
  }, [errorMsg, campaigns]);
  const campExpenses: number = useMemo(() => {
    if (errorMsg) return 0;
    const oNbr = expenses.reduce((a, c) => a + c.contractPrice, 0);
    return oNbr;
  }, [errorMsg, expenses]);  
  const campBudg: number = useMemo(() => {
    if (errorMsg) return 0;
    const oNbr = campaigns.reduce((a, c) => a + c.budget, 0);
    return oNbr;
  }, [errorMsg, campaigns]);
  const campHashtags: number = useMemo(() => {
    if (errorMsg) return 0;
    const oNbr = campaigns.reduce((a, c) => a + c.hashtag.length, 0);
    return oNbr;
  }, [errorMsg, campaigns]);


  const prevCampNbr: number = useMemo(() => {
    if (errorMsg) return 0;
    return prevCamps.length;
  }, [errorMsg, prevCamps]);
  const prevCampExpenses: number = useMemo(() => {
    if (errorMsg) return 0;
    const oNbr = prevExpenses.reduce((a, c) => a + c.contractPrice, 0);
    return oNbr;
  }, [errorMsg, prevExpenses]); 
  const prevCampBudg: number = useMemo(() => {
    if (errorMsg) return 0;
    const oNbr = prevCamps.reduce((a, c) => a + c.budget, 0);
    return oNbr;
  }, [errorMsg, prevCamps]);
  const prevCampHashtags: number = useMemo(() => {
    if (errorMsg) return 0;
    const oNbr = prevCamps.reduce((a, c) => a + c.hashtag.length, 0);
    return oNbr;
  }, [errorMsg, prevCamps]);



  return (
    <div className="flex flex-wrap">
      <DashboardToolComp
        type="campaigns"
        loading={loading}
        errorMsg={errorMsg}
        nbrValue={campNbr}
        prevValue={prevCampNbr}
      />
      <DashboardToolComp
        type="hashtag"
        loading={loading}
        errorMsg={errorMsg}
        nbrValue={campHashtags}
        prevValue={prevCampHashtags}
      />
      <DashboardToolComp
        type="budget"
        loading={loading}
        errorMsg={errorMsg}
        nbrValue={campBudg}
        prevValue={prevCampBudg}
      />
      <DashboardToolComp
        type="expenses"
        loading={loading}
        errorMsg={errorMsg}
        nbrValue={campExpenses}
        prevValue={prevCampExpenses}
      />
    </div>
  );
};

interface IDashboardToolComp {
  type: TDashboardTool;
  loading: boolean;
  errorMsg: string | null;
  nbrValue: number;
  prevValue: number;
}
const DashboardToolComp = ({
  type,
  loading,
  errorMsg,
  nbrValue,
  prevValue,
}: IDashboardToolComp) => {
  const finalNbr = nbrValue;

  const fPercent = appFxs.calculateGrowthPercent(prevValue, nbrValue);

  const tooleTitle =
    type === "campaigns"
      ? languages.campaigns
      : type === "hashtag"
      ? languages.hashtag
      : type === "budget"
      ? languages.budget
      : type === "expenses"
      ? languages.expenses
      : languages.campaigns;
  const tooleIconColor =
    type === "campaigns"
      ? "#ffc107"
      : type === "hashtag"
      ? "#50d38a"
      : type === "budget"
      ? "#60bafd"
      : type === "expenses"
      ? "#000"
      : "#ffc107";
  const tooleIcon =
    type === "campaigns"
      ? "fa fa-bookmark"
      : type === "hashtag"
      ? "fa fa-hashtag"
      : type === "budget"
      ? "fa fa-dollar"
      : type === "expenses"
      ? "fa fa-recycle"
      : "fa fa-bookmark";

  return (
    <div className="w-full md:w-1/2 lg:w-1/4">
      <div className="p-2 relative">
        {loading && (
          <div className="absolute w-full h-full flex justify-center items-center">
            <i className="fa fa-spin fa-spinner fa-3x"></i>
          </div>
        )}
        {!loading && (
          <div className="w-card">
            <div className="w-card-body">
              <h6>
                <i className={tooleIcon} style={{ color: tooleIconColor }}></i>
              </h6>
              <span className="font-semibold">{tooleTitle}</span>
              <h3>
                <span>{appFxs.formatNumberReducer(finalNbr)}</span>
              </h3>
              <small>{fPercent}% {languages.growth.toLowerCase()}</small>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

interface ILastTopBudgetCampaigns {
  loading: boolean;
  lstAll: IMonalytCampaign[];
  lstExpenses: IMonalytCampaignInfl[];
  errorMsg: string | null;
  onRefresh: () => void;
}
const LastTopBudgetCampaigns = ({
  loading,
  lstAll,
  errorMsg,
  onRefresh,
  lstExpenses,
}: ILastTopBudgetCampaigns) => {
  const lstCampaigns: IMonalytCampaign[] = useMemo(() => {
    if (errorMsg) return [];
    const sortedArr = lstAll.sort((a, b) => b.budget - a.budget).slice(0, 3);
    return sortedArr;
  }, [lstAll, errorMsg]);

  return (
    <div className="w-card w-full">
      <div className="header">
        <h2 className="text-left">
          <strong>{languages.top3}</strong>&nbsp;
          {languages.highBudgCamp.toLowerCase()}
        </h2>
      </div>
      <div className="body">
        <div className="table-responsive">
          <table className="table center-aligned">
            <thead>
              <tr className="text-sm">
                <th className="text-left">{languages.campaign}</th>
                <th className="text-right">{languages.budget}</th>
                <th className="text-right">{languages.expenses}</th>
                <th className="text-left hidden md:table-cell">{languages.start}</th>
                <th className="text-left hidden md:table-cell">{languages.end}</th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr className="text-sm">
                  <td colSpan={5}>
                    <div className="flex flex-col justify-center items-center py-2 space-y-3">
                      <i className="fa fa-spin fa-spinner fa-3x"></i>
                      <h3 className="text-lg">{languages.loading}...</h3>
                    </div>
                  </td>
                </tr>
              )}
              {!loading && errorMsg && (
                <tr className="text-sm">
                  <td colSpan={5}>
                    <div className="flex flex-col justify-center items-center py-2 space-y-3">
                      <h3 className="text-base">{errorMsg}</h3>
                      <Button onClick={() => onRefresh()}>
                        {languages.refresh}
                      </Button>
                    </div>
                  </td>
                </tr>
              )}

              {!loading && !errorMsg && lstCampaigns.length === 0 && (
                <tr className="text-sm">
                  <td colSpan={5}>
                    <div className="flex flex-col justify-center items-center py-2 space-y-3">
                      <h3 className="text-base">{languages.noRecFnd}</h3>
                      <Button onClick={() => onRefresh()}>
                        {languages.refresh}
                      </Button>
                    </div>
                  </td>
                </tr>
              )}

              {!loading && !errorMsg && lstCampaigns.length > 0 && (
                <>
                  {lstCampaigns.map((o, idx) => (
                    <BudgetRowElement
                      key={idx}
                      data={o}
                      expnses={lstExpenses.filter(
                        (x) => x.campaignKey === o.campaignKey
                      )}
                    />
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

interface IBudgetRowElement {
  data: IMonalytCampaign;
  expnses: IMonalytCampaignInfl[];
}
const BudgetRowElement = ({ data, expnses }: IBudgetRowElement) => {
  const oExpense = expnses.reduce((a, c) => a + c.contractPrice, 0);

  return (
    <tr className="text-sm">
      <td className="text-left">
        <Link
          to={`/app/campaign/editor/${data.campaignKey}`}
          className="text-blue-500 hover:text-blue-700 hover:underline cursor-pointer"
        >
          {data.title}
        </Link>
      </td>
      <td className="text-right">
        {currency}&nbsp;{appFxs.formatNumbers(data.budget)}
      </td>
      <td className="text-right">
        {currency}&nbsp;{appFxs.formatNumbers(oExpense)}
      </td>
      <td className="text-left hidden md:table-cell">
        {appFxs.formatDate(new Date(data.startDate))}
      </td>
      <td className="text-left hidden md:table-cell">{appFxs.formatDate(new Date(data.endDate))}</td>
    </tr>
  );
};
