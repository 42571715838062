import { Button, DropdownMenu } from "@radix-ui/themes";
import { Link } from "react-router-dom";
import { IMonalytCampaign } from "src/models/monalytics_models";

import getLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import { listOfCampaignChannels } from "src/utils/tempo_data";
import { ReactNode } from "react";
import { DropDownMenuAction, IMenuDropDownAct } from "src/models/smarttypes";

const languages = getLang();
const appFxs = generalFxs();

interface ILinkRouter {
  label: string;
  href: string;
}

export const LinkRouter = ({ href, label }: ILinkRouter) => {
  return (
    <Link className="text-blue-500 hover:text-blue-800" to={href}>
      {label}
    </Link>
  );
};

export const AnchorLink = ({ href, label }: ILinkRouter) => {
  return (
    <a
      rel="noreferrer"
      target="_blank"
      className="text-blue-500 hover:text-blue-800"
      href={href}
    >
      {label}
    </a>
  );
};

interface ISeparatorWithText {
  label: string;
}

export const SeparatorWithText = ({ label }: ISeparatorWithText) => {
  return (
    <div className="w-full relative">
      <hr />
      <span className="text-sm px-3 bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        {label}
      </span>
    </div>
  );
};

interface ICircularButtonIcon {
  title?: string;
  onClick?: () => void;
  btnType: "envelope" | "bell";
}

export const CircularButtonIcon = ({
  onClick,
  title,
  btnType,
}: ICircularButtonIcon) => {
  const iconCss =
    btnType === "envelope"
      ? "fa fa-envelope"
      : btnType === "bell"
      ? "fa fa-bell"
      : "fa fa-envelope";
  return (
    <button
      onClick={onClick}
      title={title}
      className="flex items-center justify-center rounded-full border-none bg-transparent hover:bg-neutral-200 w-10 h-10 p-2 transition-colors"
    >
      <i className={`${iconCss} text-[18px] text-black/40`}></i>
    </button>
  );
};

interface IWiseSimpleButton {
  label: string;
  working?: boolean;
  disabled?: boolean;
  btnType?: "default" | "primary";
  onClick?: () => void;
}
export const WiseSimpleButton = ({
  label,
  disabled,
  btnType,
  onClick,
  working,
}: IWiseSimpleButton) => {
  const defaultCss = `inline-flex items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background text-secondary-foreground h-11 px-8 rounded-md dark:bg-muted dark:hover:bg-muted/80 w-full bg-black/5 hover:bg-black/10`;
  const primaryCss = `inline-flex items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-[#027E6F] text-white hover:bg-[#027E6F] h-11 px-8 rounded-md w-full`;

  const isWorking = working ?? false;

  const btnCss =
    btnType === undefined
      ? defaultCss
      : btnType === "default"
      ? defaultCss
      : primaryCss;

  const handleClick = () => {
    if (!onClick) return;
    onClick!();
  };

  return (
    <button
      onClick={handleClick}
      disabled={disabled ?? false}
      type="button"
      className={`${btnCss} `}
    >
      {isWorking && (
        <>
          <i className="fa fa-spin fa-spinner"></i>&nbsp;
        </>
      )}
      <span>{label}</span>
    </button>
  );
};

interface ErrorTableRowProps {
  colSpan: number;
  messageDisplay?: string;
  actionToTake?: () => void;
  buttonIconCss?: string;
}
interface NoRecordTableRowProps {
  colSpan: number;
  messageDisplay: string;
  actionToTake?: () => void;
  buttonIconCss?: string;
}

export const ErrorTableRow = ({
  colSpan,
  messageDisplay,
  actionToTake,
  buttonIconCss,
}: ErrorTableRowProps) => {
  const iconCss = buttonIconCss ?? "fa fa-history";
  const handleClick = () => {
    if (!actionToTake) return;
    actionToTake!();
  };
  return (
    <tr className="text-sm">
      <td colSpan={colSpan}>
        <div className="h-auto py-2 flex flex-col justify-center items-center space-y-2">
          <div className="flex flex-col items-center justify-center">
            <h3 className="text-lg my-3">{messageDisplay}</h3>

            <button
              type="button"
              className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
              onClick={handleClick}
            >
              <i className={iconCss}></i>
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
};

export const NoRecordTableRow = ({
  colSpan,
  messageDisplay,
  actionToTake,
  buttonIconCss,
}: NoRecordTableRowProps) => {
  const handleClick = () => {
    if (!actionToTake) return;
    actionToTake!();
  };
  return (
    <tr className="text-sm">
      <td colSpan={colSpan}>
        <div className="h-auto py-2 flex flex-col justify-center items-center space-y-2">
          <div className="flex flex-col items-center justify-center">
            <h3 className="text-lg my-3">{messageDisplay}</h3>
            {actionToTake && <button
              type="button"
              className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
              onClick={handleClick}
            >
              <i className={buttonIconCss ?? 'fa fa-plus'}></i>
            </button>}
          </div>
        </div>
      </td>
    </tr>
  );
};

interface LoadingTableRowProps {
  colSpan: number;
  loadText?: string;
}
export const LoadingTableRow = ({
  colSpan,
  loadText,
}: LoadingTableRowProps) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="h-auto py-2 flex flex-col justify-center items-center space-y-2">
          <span className="fa fa-spinner fa-spin fa-3x"></span>
          <h3 className="font-semibold text-base">
            {loadText ?? languages.loading}
          </h3>
        </div>
      </td>
    </tr>
  );
};

interface ILabelWithData {
  caption: string;
  data: string;
  orient?: "row" | "col";
  isArea?: boolean;
}
export const LabelWithData = ({
  caption,
  data,
  orient,
  isArea,
}: ILabelWithData) => {
  const oOrient = orient ?? "col";
  const bArea = isArea ?? false;
  return (
    <div
      data-orient={oOrient}
      className="flex flex-col space-y-1 data-[orient=row]:flex-row data-[orient=row]:space-y-0 data-[orient=row]:space-x-3 data-[orient=row]:items-center"
    >
      <span
        data-orient={oOrient}
        className="font-semibold data-[orient=row]:w-[80px] text-sm"
      >
        {caption}
      </span>
      <div
        data-isarea={bArea}
        className="px-2 py-2 border flex-1 rounded-lg data-[isarea=true]:min-h-[80px]"
      >
        {data}
      </div>
    </div>
  );
};

export const HashtagEltComp = ({
  data,
  tag,
}: {
  data: IMonalytCampaign;
  tag: string;
}) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <span className="cursor-pointer text-blue-500 hover:text-blue-700">
          #{tag}
        </span>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        {listOfCampaignChannels
          .filter((x) => data.influencerChannels.includes(x.value))
          .map((o, idx) => (
            <DropdownMenu.Item
              className="cursor-pointer"
              onClick={() => appFxs.openSearchTag(o.value, tag)}
              key={idx}
            >
              <span>{o.label}</span>
            </DropdownMenu.Item>
          ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

interface IErrorMsgDisplayer {
  msg: string;
  onAction?: () => void;
  actionTitle?: string;
  btnIcon?: ReactNode;
}
export const ErrorMsgDisplayer = ({
  msg,
  onAction,
  actionTitle,
  btnIcon,
}: IErrorMsgDisplayer) => {
  const btnTitle = actionTitle ?? languages.refresh;
  return (
    <div className="flex flex-col space-y-3">
      <p className="text-center text-base">{msg}</p>
      <div className="text-center">
        {onAction && (
          <Button onClick={() => onAction()}>
            {btnIcon && btnIcon}
            {btnTitle}
          </Button>
        )}
      </div>
    </div>
  );
};

interface INoRecordMsgDisplayer {
  msg?: string;
  onAction?: () => void;
  actionTitle?: string;
  btnIcon?: ReactNode;
}
export const NoRecordMsgDisplayer = ({
  msg,
  onAction,
  actionTitle,
  btnIcon,
}: INoRecordMsgDisplayer) => {
  const btnTitle = actionTitle ?? languages.add;
  const oMsg = msg ?? languages.noRecFnd;
  return (
    <div className="flex flex-col space-y-3">
      <p className="text-center text-lg">{oMsg}</p>
      <div className="text-center">
        {onAction && (
          <Button color="indigo" onClick={() => onAction()}>
            <div className="flex items-center space-x-2">
              {btnIcon && btnIcon}
              <span>{btnTitle}</span>
            </div>
          </Button>
        )}
      </div>
    </div>
  );
};

interface ISmallButtonRounded {
  label: string;
  onAction?: () => void;
  hasDropDown?: boolean;
  child?: ReactNode;
  disabled?: boolean;
  destructive?: boolean;
  title?: string;
}

export const SmallButtonRounded = ({
  label,
  hasDropDown,
  onAction,
  child,
  disabled,
  destructive,
  title,
}: ISmallButtonRounded) => {
  const bDis = disabled || false;
  const bDestr = destructive || false;

  const onClick = () => {
    if (!onAction) return;
    onAction!();
  };
  return (
    <button
      title={title}
      className="btn-xs data-[destr=true]:bg-red-500 data-[destr=true]:text-white"
      onClick={onClick}
      disabled={bDis}
      data-disabled={bDis}
      data-destr={bDestr}
    >
      {child && <span>{child}&nbsp;</span>}
      <span>{label}</span>&nbsp;
      {hasDropDown && <i className="fa fa-chevron-down"></i>}
    </button>
  );
};

interface IWiseCircularActionBtn {
  type: "add" | "minus";
  onAction?: () => void;
}

export const WiseCircularActionBtn = ({
  type,
  onAction,
}: IWiseCircularActionBtn) => {
  const cssBtn =
    type === "add"
      ? "fa fa-plus"
      : type === "minus"
      ? "fa fa-minus"
      : "fa fa-plus";
  return (
    <button
      onClick={onAction}
      className="group transition w-8 h-8 rounded-full flex items-center justify-center border-black border-2 bg-neutral-300 hover:bg-neutral-400"
    >
      <i className={`${cssBtn} group-hover:text-white transition`}></i>
    </button>
  );
};
interface ICalendarViewerComp {
  calDate: Date;
}
export const CalendarViewerComp = ({ calDate }: ICalendarViewerComp) => {
  const iMonth = calDate.getMonth();
  const iDay = calDate.getDate();
  const lstMonths = appFxs.wdMonthsShrt;

  return (
    <div className="calldata">
      <div className="calldata-month">{lstMonths[iMonth]}</div>
      <div className="calldata-day">
        {iDay.toString().length === 1 ? `0${iDay}` : iDay.toString()}
      </div>
    </div>
  );
};

export const DropDownFinanceMenu = ({
  data,
  onMenuClick,
}: {
  data: IMenuDropDownAct;
  onMenuClick: (o: IMenuDropDownAct) => void;
}) => {
  return (
    <li onClick={() => onMenuClick(data)}>
      <span className="link">
        <i className={data.icon}></i>
        {data.label}
        <small>{data.subLabel}</small>
      </span>
    </li>
  );
};

interface IWiseCheckBox {
  value: boolean;
  size?: number;
  onChangeValue: () => void;
}
export const WiseCheckBox = ({ value, size, onChangeValue }: IWiseCheckBox) => {
  return (
    <div
      className="flex items-center justify-center border cursor-pointer"
      style={{ width: size ?? 20, height: size ?? 20 }}
      onClick={() => onChangeValue()}
    >
      {value && <i className="fa fa-check"></i>}
    </div>
  );
};

export const DropDownMenuItemRadix = ({
  menu,
  onMenuAct,
}: {
  menu: IMenuDropDownAct;
  onMenuAct: (action: DropDownMenuAction) => void;
}) => {
  return (
    <>
      {menu.separator && <DropdownMenu.Separator />}
      <DropdownMenu.Item
        onClick={() => onMenuAct(menu.actionType)}
        color={menu.destructive ? "red" : undefined}
        className={`cursor-pointer min-w-[120px] ${
          menu.destructive && "font-semibold"
        } `}
      >
        <div className="flex items-center space-x-3">
          {menu.icon && <i className={menu.icon}></i>}
          <span>{menu.label}</span>
        </div>
      </DropdownMenu.Item>
    </>
  );
};

//#region Tables
interface ITableComponent {
  children?: ReactNode;
  extraSmall?: boolean;
  className?: boolean;
  headerCells: ITableHeadCell[];
  hdrUpperCase?: boolean;
  hdrBold?: boolean;
  hdrClickable?: boolean;
}
export interface ITableHeadCell {
  text: string;
  clickable?: boolean;
  className?: string;
  icon?: ReactNode;
  bHdrUp?: boolean;
  bHdrBold?: boolean;
  bHdrClick?: boolean;
}
export const TableComponent = ({
  children,
  extraSmall,
  className,
  headerCells,
  hdrBold,
  hdrUpperCase,
  hdrClickable,
}: ITableComponent) => {
  const bXS = extraSmall ?? true;
  const bHdrUp = hdrUpperCase ?? false;
  const bHdrBold = hdrBold ?? false;
  const bHdrClick = hdrClickable ?? false;
  return (
    <table
      data-xs={bXS}
      className={`table ${className ? className : ""} data-[xs=true]:text-xs`}
    >
      <thead>
        <tr>
          {headerCells.map((o, idx) => (
            <TableHeadCell
              key={idx}
              {...o}
              bHdrBold={bHdrBold}
              bHdrClick={bHdrClick}
              bHdrUp={bHdrUp}
            />
          ))}
        </tr>
      </thead>
      <tbody>{children && <>{children}</>}</tbody>
    </table>
  );
};
export const TableHeadCell = ({
  text,
  clickable,
  icon,
  className,
  bHdrBold,
  bHdrClick,
  bHdrUp,
}: ITableHeadCell) => {
  const bClick = bHdrClick ?? false;
  const bUpper = bHdrUp ?? false;
  const bBold = bHdrBold ?? false;

  return (
    <th
      data-click={bClick}
      className={`${
        className ? className : ""
      } data-[click=true]:cursor-pointer`}
    >
      <span
        data-bold={bBold}
        className={`flex items-center ${
          className && className
        } data-[bold=true]:font-semibold`}
      >
        {icon && <>{icon}&nbsp;</>}
        <span
          data-upper={bUpper}
          data-bold={bBold}
          className={`flex-1 data-[upper=true]:uppercase data-[click=true]:cursor-pointer data-[bold=true]:font-semibold`}
        >
          {text}
        </span>
      </span>
    </th>
  );
};
export interface ITableBodyCell {
  children: ReactNode;
  className?: string;
  width?: number;
  align?: "start" | "center" | "end";
}

export const TableBodyCell = ({
  children,
  className,
  width,
  align,
}: ITableBodyCell) => {
  const oW = width ? `${width}px` : "auto";
  const oAl = align ?? "start";

  return (
    <td
      className={`align-middle ${className ? className : ""}`}
      style={{ width: oW }}
    >
      <div
        data-align={oAl}
        className="flex items-center data-[align=start]:justify-start  data-[align=center]:justify-center  data-[align=end]:justify-end"
      >
        {children}
      </div>
    </td>
  );
};
//#endregion


