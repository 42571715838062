import React, { ReactNode } from "react";

import { ReactComponent as AppleIcon } from "src/assets/images/svg/icons/apple.svg";
import { ReactComponent as GithubIcon } from "src/assets/images/svg/icons/github.svg";
import { ReactComponent as GoogleIcon } from "src/assets/images/svg/icons/google.svg";
import { ReactComponent as MicrosoftIcon } from "src/assets/images/svg/icons/microsoft.svg";
import { ReactComponent as TwitterIcon } from "src/assets/images/svg/icons/twitter.svg";
import { ReactComponent as Facebook } from "src/assets/images/svg/icons/facebook.svg";

//import appLanguages from "src/utils/language";
//const languages = appLanguages();

interface ButtonLinkWithIconProps {
  buttonType:
    | "google"
    | "apple"
    | "github"
    | "twitter"
    | "microsoft"
    | "facebook";
  onClick?: () => void;
  isFull?: boolean;
}

export default function ButtonLinkWithIcon({
  buttonType,
  onClick,
  isFull,
}: ButtonLinkWithIconProps) {
  const IconSvg =
    buttonType === "apple"
      ? AppleIcon
      : buttonType === "github"
      ? GithubIcon
      : buttonType === "google"
      ? GoogleIcon
      : buttonType === "microsoft"
      ? MicrosoftIcon
      : buttonType === "twitter"
      ? TwitterIcon
      : buttonType === "facebook"
      ? Facebook
      : AppleIcon;

  const IconTitle =
    buttonType === "apple"
      ? "Apple"
      : buttonType === "github"
      ? "Github"
      : buttonType === "google"
      ? "Google"
      : buttonType === "microsoft"
      ? "Microsoft"
      : buttonType === "twitter"
      ? "Twitter"
      : buttonType === "facebook"
      ? "Facebook"
      : "Apple";

  const bWider = isFull ?? false;

  const onButtonClick = () => {
    if (!onClick) return;
    onClick!();
  };

  return (
    <button
      data-width={bWider}
      className={`border border-solid bg-gray-50 border-gray-200 rounded-lg flex justify-center items-center py-2 hover:bg-gray-100 data-[width=true]:w-full data-[width=false]:w-fit`}
      onClick={onButtonClick}
    >
      <div className={`flex items-center gap-2 px-2 relative w-full`}>
        <IconSvg
          width={30}
          height={30}
          className="absolute left-2 top-1/2 -mt-[15px]"
        />
        <strong className="tracking-widest flex-1 text-center w-full text-sm">
          {IconTitle}
        </strong>
      </div>
    </button>
  );
}

interface ISmallWiseBtn {
  isBusy?: boolean;
  disabled?: boolean;
  label: string;
  onClick: () => void;
}
export const SmallWiseBtn = ({
  isBusy,
  label,
  onClick,
  disabled,
}: ISmallWiseBtn) => {
  const bBusy = isBusy ?? false;
  const bDisabled = disabled ?? false;

  return (
    <button
      className={`btn btn-sm text-white px-[1.2rem] rounded-md bg-green-400 hover:bg-green-700 disabled:bg-gray-400 disabled:text-gray-200 disabled:cursor-not-allowed`}
      onClick={onClick}
      disabled={bBusy || bDisabled}
    >
      <span className="flex flex-row items-center gap-2">
        {bBusy && <i className="fa fa-spin fa-spinner"></i>}
        <span>{label}</span>
      </span>
    </button>
  );
};

interface IWiseSmallButton {
  icon?: ReactNode;
  label: string;
  disabled?: boolean;
  busy?: boolean;
  onAction?: () => void;
  color?:
    | "gray"
    | "gold"
    | "bronze"
    | "brown"
    | "yellow"
    | "amber"
    | "orange"
    | "tomato"
    | "red"
    | "ruby"
    | "crimson"
    | "pink"
    | "plum"
    | "purple"
    | "violet"
    | "iris"
    | "indigo"
    | "blue"
    | "cyan"
    | "teal"
    | "jade"
    | "green"
    | "grass"
    | "lime"
    | "mint"
    | "sky";
}
export const WiseSmallButton = ({
  label,
  icon,
  disabled,
  color,
  onAction,
  busy,
}: IWiseSmallButton) => {
  const bDisabled = disabled ?? false;
  const bBusy = busy ?? false;
  const textColor = !color ? "#000" : color === "gray" ? "#fff" : "#fff";

  const onClick = () => {
    if (!onAction) return;
    onAction!();
  };

  return (
    <button
      type="button"
      onClick={onClick}
      data-color={color}
      className="border px-2 py-1 rounded-md hover:bg-slate-100 transition-all"
      style={{
        backgroundColor: color ?? undefined,
        color: textColor,
      }}
      disabled={bDisabled}
    >
      <span className="flex items-center space-x-1">
      {(icon && !bBusy) && <span>{icon}&nbsp;</span>}
      {bBusy && <i className="fa fa-spinner fa-spin"></i>}
      <span>{label}</span>
      </span>
    </button>
  );
};

interface IButtonRowAction {
  title?: string;
  icon: ReactNode;
  onAction: () => void;
  danger?: boolean;
}

export const ButtonRowAction = ({title, icon, onAction, danger}:IButtonRowAction) => {
  const bDanger = danger ?? false;

  return (
    <button
      data-danger={bDanger}
      title={title ?? ''}
      onClick={onAction}
      type="button"
      className={`${bDanger ? 'p-2 border-red-400 flex items-center justify-center text-xs border rounded hover:bg-gray-100 hover:shadow-sm': 'p-2 flex items-center justify-center text-xs border rounded hover:bg-gray-100 hover:shadow-sm'}`}
    >
      {icon}
    </button>
  );
};