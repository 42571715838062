import { emulateTab } from "emulate-tab";
import { useNumberFormat } from "@react-input/number-format";
import { Checkbox } from "@radix-ui/themes";

import getLanguages from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import { listOfCampaignChannels } from "src/utils/tempo_data";
import { IInfluncChannel } from "src/models/smarttypes";
import { NumericFormat } from "react-number-format";

const languages = getLanguages();
const appFxs = generalFxs();

interface IWiseModalTextInput {
  dateValue?: Date;
  fieldName: string;
  label: string;
  value: string | number;
  disabled?: boolean;
  onChange?: (f: string, v: string | number) => void;
  errorText?: string;
  placeholder?: string;
  graphicType?:
    | "email"
    | "person"
    | "calendar"
    | "phone"
    | "person"
    | "lock"
    | "key"
    | "office"
    | "sex"
    | "folder"
    | "location"
    | "map"
    | "file"
    | "note"
    | "notes"
    | "cash"
    | undefined;
  fldType: React.HTMLInputTypeAttribute | "select" | "textarea";
  selectOptions?: { value: string | number; label: string }[];
  maxLength?: number;
  isNumeric?: boolean;
  selectallFocus?: boolean;
  moveToNext?: boolean;
  useTabEnter?: boolean;
  tabIndex?: number;
  showLabel?: boolean;
  rows?: number;
  capCaption?: boolean;
}

export default function WiseModalTextInput({
  value,
  disabled,
  fieldName,
  onChange,
  errorText,
  fldType,
  label,
  placeholder,
  maxLength,
  isNumeric,
  graphicType,
  selectallFocus,
  selectOptions,
  moveToNext,
  useTabEnter,
  tabIndex,
  showLabel,
  rows,
  capCaption,
}: IWiseModalTextInput) {
  const placH = placeholder ?? languages.fillThisField;
  const mLength = maxLength ?? 35;
  const bNumeric = isNumeric ?? false;
  const fType = fldType ?? "text";
  const bSelectAllFoc = selectallFocus ?? true;
  const sOptions = selectOptions ?? [
    { value: "", label: languages.notSpecified },
  ];

  const bMoveToNext = moveToNext ?? false;
  const bUseTabEnter = useTabEnter ?? true;
  const bShowLabel = showLabel ?? false;
  const bCapLetter = capCaption ?? false;

  const iconClass = !graphicType
    ? null
    : graphicType === "email"
    ? "bi bi-envelope"
    : graphicType === "person"
    ? "bi bi-file-person"
    : graphicType === "calendar"
    ? "bi bi-calendar3"
    : graphicType === "phone"
    ? "bi bi-telephone"
    : graphicType === "lock"
    ? "bi bi-lock"
    : graphicType === "key"
    ? "bi bi-key"
    : graphicType === "office"
    ? "bi bi-building"
    : graphicType === "sex"
    ? "bi bi-bezier2"
    : graphicType === "folder"
    ? "bi bi-folder"
    : graphicType === "location"
    ? "bi bi-house-down-fill"
    : graphicType === "map"
    ? "bi bi-pin-map-fill"
    : graphicType === "file"
    ? "bi bi-file-earmark-break-fill"
    : graphicType === "note"
    ? "bi bi-stickies-fill"
    : graphicType === "notes"
    ? "bi bi-journals"
    : graphicType === "cash"
    ? "bi bi-cash"
    : "bi bi-envelope";

  const onChangeVal = (fldVal: string) => {
    if (!onChange) return;
    onChange!(fieldName, fldVal);
  };
  const onFocusInput = (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>
  ) => {
    if (!bSelectAllFoc) return;
    e.target.select();
  };

  const onKeyPress = (
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (bNumeric) appFxs.strictlyNbr(e);
  };

  const onInputKeyup = (
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (e.key === "Enter") {
      if (bUseTabEnter) emulateTab();
    } else {
      const len = e.currentTarget.value.length;
      if (len === mLength && bMoveToNext) emulateTab();
    }
  };

  return (
    <label className="flex flex-col mb-2">
      {bShowLabel && (
        <div className="label">
          <span
            className={`label-text text-sm font-semibold ${
              bCapLetter && "uppercase"
            }`}
          >
            {label}
          </span>
        </div>
      )}
      <div
        className={`relative border border-solid ${
          errorText ? "border-red-400" : "border-gray-400"
        } rounded-md px-2 ${
          errorText
            ? "focus-within:border-red-600"
            : "focus-within:border-gray-600"
        }`}
      >
        <div
          data-type={fType}
          className={`flex flex-row items-center data-[type='textarea']:items-start gap-2`}
        >
          {graphicType && (
            <i
              data-error={errorText && errorText.length > 0}
              data-type={fType}
              className={`${iconClass} text-gray-500 data-[error=true]:text-red-500 data-[type='textarea']:mt-4`}
            ></i>
          )}
          {fType === "textarea" && (
            <textarea
              data-error={errorText && errorText.length > 0}
              data-type={fType}
              placeholder={placH.toLowerCase()}
              className={`resize-none flex-1 text-black data-[error=true]:text-error font-light focus:border-none focus-visible:border-none focus-visible:outline-none py-3 disabled:bg-transparent placeholder:text-gray-400/80" data-[error=true]:placeholder:text-red-400/60`}
              value={value}
              name={fieldName}
              id={fieldName}
              onChange={(e) => onChangeVal(e.target.value)}
              disabled={disabled ?? false}
              maxLength={mLength}
              onFocus={onFocusInput}
              onKeyPress={onKeyPress}
              onKeyUp={onInputKeyup}
              tabIndex={tabIndex}
              rows={rows ?? 3}
            ></textarea>
          )}

          {!(fType === "select" || fType === "textarea") && (
            <input
              type={fType}
              placeholder={placH.toLowerCase()}
              className={`flex-1 ${
                errorText ? "text-error" : "text-black"
              } font-light focus:border-none focus-visible:border-none focus-visible:outline-none py-3 disabled:bg-transparent ${
                errorText
                  ? "placeholder:text-red-400/60"
                  : "placeholder:text-gray-400/80"
              }`}
              value={value}
              name={fieldName}
              id={fieldName}
              onChange={(e) => onChangeVal(e.target.value)}
              disabled={disabled ?? false}
              maxLength={mLength}
              onFocus={onFocusInput}
              onKeyPress={onKeyPress}
              onKeyUp={onInputKeyup}
              tabIndex={tabIndex}
            />
          )}
          {fType === "select" && (
            <select
              className={`flex-1 ${
                errorText ? "text-error" : "text-black"
              } font-light focus:border-none focus-visible:border-none focus-visible:outline-none py-3 disabled:bg-transparent ${
                errorText
                  ? "placeholder:text-red-400/60"
                  : "placeholder:text-gray-400/80"
              }`}
              value={value}
              name={fieldName}
              id={fieldName}
              onChange={(e) => onChangeVal(e.target.value)}
              disabled={disabled ?? false}
            >
              {sOptions.map((o, idx) => (
                <option key={idx} value={o.value}>
                  {o.label}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>

      {errorText && (
        <div className="label">
          <span className="label-text-alt text-red-500">{errorText}</span>
        </div>
      )}
    </label>
  );
}

interface IChannelsTargetField {
  fieldName: string;
  values: string[];
  onChanged: (fldN: string, val: string) => void;
  errorText?: string;
}

export const ChannelsTargetField = ({
  fieldName,
  values,
  onChanged,
  errorText,
}: IChannelsTargetField) => {
  return (
    <div className="flex flex-col">
      <div className="label-text text-sm font-semibold">
        {languages.inflChannelTarget}
      </div>
      <div className="flex items-center flex-wrap space-y-3">
        {listOfCampaignChannels.map((o, idx) => (
          <ChannelCompElt
            onChanged={(v) => onChanged(fieldName, v)}
            key={idx}
            data={o}
            values={values}
          />
        ))}
      </div>
      {errorText && (
        <div className="label">
          <span className="label-text-alt text-red-500">{errorText}</span>
        </div>
      )}
    </div>
  );
};

interface IChannelCompElt {
  data: IInfluncChannel;
  values: string[];
  onChanged: (val: string) => void;
}
const ChannelCompElt = ({ data, values, onChanged }: IChannelCompElt) => {
  return (
    <label className="flex items-center  w-1/3 cursor-pointer">
      <Checkbox
        onClick={() => onChanged(data.value)}
        id={data.value}
        checked={values.includes(data.value)}
      />
      &nbsp;
      <span className="ml-3 ">{data.label}</span>
    </label>
  );
};

interface IBootstrapInput {
  flexOrient?: "col" | "row";
  fieldName: string;
  label: string;
  value: string | number;
  disabled?: boolean;
  onChange?: (f: string, v: string | number) => void;
  errorText?: string;
  placeholder?: string;
  fldType: React.HTMLInputTypeAttribute | "select" | "textarea";
  selectOptions?: { value: string | number; label: string }[];
  maxLength?: number;
  isNumeric?: boolean;
  selectallFocus?: boolean;
  moveToNext?: boolean;
  useTabEnter?: boolean;
  tabIndex?: number;
  showLabel?: boolean;
  rows?: number;
  rounded?: boolean;
}
export const BootstrapInput = ({
  flexOrient,
  fieldName,
  label,
  errorText,
  value,
  showLabel,
  fldType,
  disabled,
  isNumeric,
  maxLength,
  moveToNext,
  onChange,
  placeholder,
  rows,
  selectOptions,
  selectallFocus,
  tabIndex,
  useTabEnter,
  rounded
}: IBootstrapInput) => {
  const oOrient = flexOrient ?? "col";

  const inputRef = useNumberFormat({ locales: 'en', maximumFractionDigits: 0 });


  const placH = placeholder ?? languages.fillThisField;
  const mLength = maxLength ?? (fldType === "textarea" ? 800 : 35);
  const bNumeric = isNumeric ?? false;
  const fType = fldType ?? "text";
  const bSelectAllFoc = selectallFocus ?? true;
  const sOptions = selectOptions ?? [
    { value: "", label: languages.notSpecified },
  ];

  const bMoveToNext = moveToNext ?? false;
  const bUseTabEnter = useTabEnter ?? true;
  const bShowLabel = showLabel ?? false;

  const onChangeVal = (fldVal: string) => {
    if (!onChange) return;
    onChange!(fieldName, fldVal);
  };
  const onChangeNumericValue = (v: string) => {
    const finalV = v.replaceAll(",", "");
    onChangeVal(finalV ?? "0");
  };
  const onFocusInput = (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>
  ) => {
    if (!bSelectAllFoc) return;
    e.target.select();
  };

  const onKeyPress = (
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (bNumeric || fType === 'number') appFxs.strictlyNbr(e);
  };

  const onInputKeyup = (
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (e.key === "Enter") {
      if (bUseTabEnter) emulateTab();
    } else {
      const len = e.currentTarget.value.length;
      if (len === mLength && bMoveToNext) emulateTab();
    }
  };

  return (
    <div
      data-orient={oOrient}
      className="form-group flex data-[orient=row]:items-center data-[orient=col]:flex-col"
    >
      {bShowLabel && (
        <label
          data-error={errorText ? true : false}
          className="col-form-label"
          htmlFor={fieldName}
        >
          {label}
        </label>
      )}
      <div className="flex-1 flex flex-col space-y-1">
        {!(
          fType === "select" ||
          fType === "textarea" ||
          fType === "date" ||
          fType === "datetime-local" ||
          fType === "number"
        ) && (
          <input
            data-round={rounded ?? false}
            type={fType}
            id={fieldName}
            name={fieldName}
            className="form-control"
            data-error={errorText ? true : false}
            value={value}
            placeholder={placH.toLowerCase()}
            onChange={(e) => onChangeVal(e.target.value)}
            disabled={disabled ?? false}
            maxLength={mLength}
            onFocus={onFocusInput}
            onKeyPress={onKeyPress}
            onKeyUp={onInputKeyup}
            tabIndex={tabIndex}
          />
        )}

        {(bNumeric && fType === "number") && (
          <NumericFormat
            value={Number(value)}
            allowLeadingZeros={false}
            thousandSeparator=","
            className="form-control text-right"
            onKeyPress={onKeyPress}
            onKeyUp={onInputKeyup}
            tabIndex={tabIndex}
            onFocus={onFocusInput}
            disabled={disabled || false}
            onChange={(e) => onChangeNumericValue(e.target.value)}
          />
        )}

        {fType === "textarea" && (
          <textarea
            data-round={rounded ?? false}
            data-error={errorText && errorText.length > 0}
            data-type={fType}
            placeholder={placH.toLowerCase()}
            className="resize-none form-control"
            value={value}
            name={fieldName}
            id={fieldName}
            onChange={(e) => onChangeVal(e.target.value)}
            disabled={disabled ?? false}
            maxLength={mLength}
            onFocus={onFocusInput}
            onKeyPress={onKeyPress}
            onKeyUp={onInputKeyup}
            tabIndex={tabIndex}
            rows={rows ?? 3}
          ></textarea>
        )}

        {fType === "select" && (
          <select
            data-round={rounded ?? false}
            className="form-control  form-select"
            value={value}
            name={fieldName}
            id={fieldName}
            onChange={(e) => onChangeVal(e.target.value)}
            disabled={disabled ?? false}
          >
            {sOptions.map((o, idx) => (
              <option key={idx} value={o.value}>
                {o.label}
              </option>
            ))}
          </select>
        )}

        {(fType === "date" || fType === "datetime-local") && (
          <input
            data-round={rounded ?? false}
            type={fType}
            className="form-control"
            aria-describedby="basic-addon1"
            id={fieldName}
            name={fieldName}
            data-error={errorText ? true : false}
            value={value}
            onChange={(e) => onChangeVal(e.target.value)}
            disabled={disabled ?? false}
            maxLength={mLength}
            onFocus={onFocusInput}
            onKeyPress={onKeyPress}
            onKeyUp={onInputKeyup}
            tabIndex={tabIndex}
          />
        )}

        {errorText && (
          <small className="form-text text-danger">{errorText}</small>
        )}
      </div>
    </div>
  );
};

interface IWiseSwitchFormElt {
  fieldName: string;
  value: boolean;
  onCheckValue: (f: string, v: boolean) => void;
  label: string;
}
export const WiseSwitchFormElt = ({
  fieldName,
  value,
  onCheckValue,
  label,
}: IWiseSwitchFormElt) => {
  return (
    <div className="flex items-center space-x-2 py-2">
      <div
        className="w-5 h-5 border flex items-center justify-center cursor-pointer"
        onClick={() => onCheckValue(fieldName, !value)}
      >
        {value && <i className="fa fa-check"></i>}
      </div>
      <span
        className="cursor-pointer"
        onClick={() => onCheckValue(fieldName, !value)}
      >
        {label}
      </span>
    </div>
  );
};
