import {
  DashboardIcon,
  BarChartIcon,
  ArchiveIcon,
  BellIcon,
  CookieIcon,
  PersonIcon,
  HomeIcon,
  GearIcon,
  AccessibilityIcon,
  
} from "@radix-ui/react-icons";
import { IInfluencer } from "src/models/monalytics_models";

import { AppMenuElt, IInfluncChannel, IManagtUser } from "src/models/smarttypes";
import getLang from "src/utils/languages";

const languages = getLang();

export const mimeTypes = [
  "image/jpeg,image/png",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
];
/*
Sponsored content. Influencers create content that features the brand’s product or service.
Product reviews. Influencers provide their honest review of a product or service to their audience.
Brand ambassador programs. Long-term partnerships where influencers continuously endorse a brand.
Affiliate marketing. Influencers earn a commission for every sale made through a link they share.
Social media takeovers. Influencers take over a brand’s social media account for a set period to engage with the brand’s audience.
Giveaways and contests. Influencers host contests or giveaways in collaboration with the brand to increase engagement and visibility.

*/

export const listOfTempoInfluencers:IInfluencer[] = [
  {
    city: 'Kigali',
    country: 'RW',
    firstName: 'Salama',
    inflEmail: 'salamank@gmail.com',
    influencerKey: '218548852121f22121',
    lastName: 'NKUNDWA',
    registerDate:new Date(),
    statusActive: true,
    mobilePhone: '2507899623256',
    photoProfile: '',
    poBox: '3226',
  },
  {
    city: 'Kigali',
    country: 'RW',
    firstName: 'Bambana',
    inflEmail: 'bambana.m@gmail.com',
    influencerKey: '218548452121f22121',
    lastName: 'MAOMBI',
    registerDate:new Date(),
    statusActive: true,
    mobilePhone: '2507898523256',
    photoProfile: '',
    poBox: '3002',
  },
  {
    city: 'Kigali',
    country: 'RW',
    firstName: 'Laurent',
    inflEmail: 'laurentma@gmail.com',
    influencerKey: '2185X8452121f22121',
    lastName: 'MATABARO',
    registerDate:new Date(),
    statusActive: true,
    mobilePhone: '2507898523444',
    photoProfile: '',
    poBox: '2582',
  },
  {
    city: 'Kigali',
    country: 'RW',
    firstName: 'Jacques',
    inflEmail: 'jtimiza@gmail.com',
    influencerKey: '2185CC8452121f22121',
    lastName: 'TIMIZA',
    registerDate:new Date(),
    statusActive: true,
    mobilePhone: '2507878523444',
    photoProfile: '',
    poBox: '9512',
  },
  {
    city: 'Kigali',
    country: 'RW',
    firstName: 'Abraham',
    inflEmail: 'abraham.b@gmail.com',
    influencerKey: '2185CC8951121f22121',
    lastName: 'BANABALE',
    registerDate:new Date(),
    statusActive: true,
    mobilePhone: '2507878443444',
    photoProfile: '',
    poBox: '98741',
  },
  {
    city: 'Kigali',
    country: 'RW',
    firstName: 'Sarah',
    inflEmail: 'sarah.b@gmail.com',
    influencerKey: '2185CC8981121f22121',
    lastName: 'MEMA',
    registerDate:new Date(),
    statusActive: true,
    mobilePhone: '2507878498444',
    photoProfile: '',
    poBox: '2222',
  },
  {
    city: 'Kigali',
    country: 'RW',
    firstName: 'Helene',
    inflEmail: 'helene.m@gmail.com',
    influencerKey: '2185CC32321121f22121',
    lastName: 'MATABARO',
    registerDate:new Date(),
    statusActive: true,
    mobilePhone: '2507878958444',
    photoProfile: '',
    poBox: '7422',
  },
  {
    city: 'Kigali',
    country: 'RW',
    firstName: 'Liliane',
    inflEmail: 'liliane.m@gmail.com',
    influencerKey: '2185CC39821121f22121',
    lastName: 'MANIRAHO',
    registerDate:new Date(),
    statusActive: true,
    mobilePhone: '2507878958624',
    photoProfile: '',
    poBox: '3256',
  },
  {
    city: 'Kigali',
    country: 'RW',
    firstName: 'Lacombe',
    inflEmail: 'lacombe.m@gmail.com',
    influencerKey: '2185CC39836521f22121',
    lastName: 'MARANATA',
    registerDate:new Date(),
    statusActive: true,
    mobilePhone: '2507879958624',
    photoProfile: '',
    poBox: '3996',
  },
  {
    city: 'Kigali',
    country: 'RW',
    firstName: 'Cynthia',
    inflEmail: 'cynthia.m@gmail.com',
    influencerKey: '2185DD39836521f22121',
    lastName: 'MUNIATA',
    registerDate:new Date(),
    statusActive: true,
    mobilePhone: '2507879925624',
    photoProfile: '',
    poBox: '4596',
  },
]


export const listOfActivePages: AppMenuElt[] = [
  {
    id: 0,
    label: languages.dashboard,
    href: "/app/dashboard",
    icon: DashboardIcon,
  },
  {
    id: 1,
    label: languages.analysis,
    href: "/app/analysis",
    icon: BarChartIcon,
  },
  { id: 2, label: languages.profile, href: "/app/profile", icon: ArchiveIcon },
  {
    id: 3,
    label: languages.influencers,
    href: "/app/influencers",
    icon: BellIcon,
  },
  {
    id: 4,
    label: languages.campaigns,
    href: "/app/campaign",
    icon: CookieIcon,
  },
  {
    id: 5,
    label: languages.settings,
    href: "/app/settings/settings",
    icon: CookieIcon,
  },
  {
    id: 6,
    label: languages.myProfile,
    href: "/app/settings/myprofile",
    icon: CookieIcon,
  },
  {
    id: 7,
    label: languages.users,
    href: "/app/settings/users",
    icon: CookieIcon,
  },
  {
    id: 8,
    label: languages.newUser,
    href: "/app/settings/users/newuser",
    icon: CookieIcon,
  },
  {
    id: 9,
    label: languages.user_editor,
    href: "/app/settings/users/preiew/",
    icon: CookieIcon,
  },
  {
    id: 10,
    label: languages.company,
    href: "/app/settings/company",
    icon: CookieIcon,
  },
];

export const listOfAppMenus: AppMenuElt[] = [
  {
    id: 0,
    label: languages.dashboard,
    href: "/app/dashboard",
    icon: DashboardIcon,
  },
  {
    id: 1,
    label: languages.analysis,
    href: "/app/analysis",
    icon: BarChartIcon,
  },
  {
    id: 3,
    label: languages.influencers,
    href: "/app/influencers",
    icon: BellIcon,
  },
  {
    id: 4,
    label: languages.campaigns,
    href: "/app/campaign",
    icon: CookieIcon,
  },
];

export const listOfSettsMenus: AppMenuElt[] = [
  {
    id: 0,
    label: languages.settings,
    href: "/app/settings/settings",
    icon: GearIcon,
  },
  {
    id: 1,
    label: languages.myProfile,
    href: "/app/settings/myprofile",
    icon: AccessibilityIcon,
  },
  {
    id: 2,
    label: languages.users,
    href: "/app/settings/users",
    icon: PersonIcon,
  },
  {
    id: 3,
    label: languages.company,
    href: "/app/settings/company",
    icon: HomeIcon,
  },
  {
    id: 4,
    label: languages.smsSetts,
    href: "/app/settings/smssetts",
    icon:  HomeIcon,
  },
];


export const listOfCampaignChannels:IInfluncChannel[] = [
  {label: 'Facebook', value: 'facebook'},
  {label: 'Instagram', value: 'instagram'},
  {label: 'LinkedIn', value: 'linkedin'},
  {label: 'Snapchat', value: 'snapchat'},
  {label: 'Twitter', value: 'twitter'},
  {label: 'TikTok', value: 'tiktok'},
]

export const templateHtml = `
<p _ngcontent-pmd-c205="" class="tbl-item-text-sub-item"><p><a href="https://fr.wikipedia.org/wiki/Wikip%C3%A9dia">Wikipédia</a> est un projet d’encyclopédie collective en ligne, universelle, multilingue et fonctionnant sur le principe du <a href="https://fr.wikipedia.org/wiki/Wiki">wiki</a>. Ce projet vise à offrir un contenu librement réutilisable, objectif et vérifiable, que chacun peut modifier et améliorer.</p> <br><br>==============<br><strong>Created by:</strong> Kayihura James<br><strong>On behalf of:</strong> Peacock Ltd<br><strong>Phone number:</strong> 250788307310<br></p>
`

export const lstManagementUsers:IManagtUser[] = [
  {userEmail: 'jkayihura@msn.com', userName: 'James KAYIHURA', userPassword: 'Jk&7919@', userType: 'admin'},
  {userEmail: 'ngoumsstephane1994@gmail.com', userName: 'Freddy Daniel NGOUM', userPassword: 'FDn456', userType: 'admin'},
]
