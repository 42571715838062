/* eslint-disable react-hooks/exhaustive-deps */

import { ReactNode, useEffect, useState } from "react";
import ReactDom from "react-dom";

import getLanguages from "src/utils/languages";
import getApps from "src/utils/general_fx";

import { Button } from "@radix-ui/themes";

import {
  IInfluencer,
  IMonalytCampaignInfl,
} from "src/models/monalytics_models";
import { BootstrapInput } from "./WiseModalTextInput";
import { IFormError, IMaterialModalDialog } from "src/models/smarttypes";
import NetServices from "src/utils/netservices";


const languages = getLanguages();
const appFxs = getApps();

interface IWiseModel {
  children: ReactNode;
  isOpen: boolean;
  onClose: (retData: any) => void;
  width?: number;
  titleBox?: string;
  hasFooter?: boolean;
}

const MODAL_STYLES = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
  padding: "0px",
  zIndex: 1000,
};
const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,.7)",
  zIndex: 1000,
};

export const WiseModal = ({
  children,
  isOpen,
  onClose,
  width,
  titleBox,
  hasFooter,
}: IWiseModel) => {
  if (!isOpen) return null;
  const nodeElement = document.getElementById("portals")!;
  const bFooter = hasFooter ?? true;

  return ReactDom.createPortal(
    <>
      <div style={{ ...OVERLAY_STYLES, position: "fixed" }}></div>
      <div
        style={{
          ...MODAL_STYLES,
          borderRadius: "7px",
          overflow: "hidden",
          position: "fixed",
          width: !width ? "450px" : `${width}px`,
        }}
      >
        <div
          className="border-b border-slate-300 border-solid"
          style={{
            padding: "10px 15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span className="font-bold">
            {titleBox ?? languages.dialogBoxTitle}
          </span>
          <button onClick={() => onClose(null)}>
            <i className="fa fa-times"></i>
          </button>
        </div>
        <div className="px-2 py-1">{children}</div>
        {bFooter && (
          <div className="dialog-footer flex gap-3 justify-end p-[15px] border-t border-slate-300 border-solid">
            <Button onClick={() => onClose(null)}>
              <span>{languages.cancel}</span>
            </Button>
          </div>
        )}
      </div>
    </>,
    nodeElement
  );
};

interface IModalCampHireInfluencer {
  isOpen: boolean;
  onClose: (retData: any) => void;
  dataRecord: IMonalytCampaignInfl;
  influencers: IInfluencer[];
  dialogStatus: number;
}
export const ModalCampHireInfluencer = ({
  isOpen,
  onClose,
  dataRecord,
  influencers,
  dialogStatus,
}: IModalCampHireInfluencer) => {
  const [saving, setSaving] = useState(false);
  const [dataForm, setDataForm] = useState<IMonalytCampaignInfl>({
    ...dataRecord,
  });
  const [formErrors, setFormErrors] = useState<IFormError>({});

  const fOptions = influencers.map((o) => {
    return {
      label: `${o.firstName} ${o.lastName}`,
      value: o.influencerKey,
    };
  });
  const oOptions = [...fOptions, { label: "", value: "" }];

  const onChange = (f: string, v: string | number) => {
    if (f === "hiredDate") {
      const d = new Date(v);
      setDataForm({ ...dataForm, [f]: d });
    } else {
      setDataForm({ ...dataForm, [f]: v });
    }
  };

  const onSaveRecord = async () => {
    if (dialogStatus === 1) {
      onClose(null);
      return;
    }
    const errors: IFormError = {};

    if (!dataForm.influencerKey || dataForm.influencerKey.length === 0) {
      errors.influencerKey = languages.fldRequired;
    }
    if (dataForm.hiredDate.getTime() > new Date().getTime()) {
      errors.hiredDate = languages.fldRequired;
    }
    if (!dataForm.contractPrice) {
      errors.contractPrice = languages.fldRequired;
    } else if (dataForm.contractPrice === 0) {
      errors.contractPrice = languages.priceInsuff;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setSaving(true);

    const finalData:IMonalytCampaignInfl = {
      ...dataForm,
      contractPrice: Number(
        dataForm.contractPrice.toString().replace(/,/g, "")
      ),
    }

    const oOper = await NetServices.requestPost(
      "monalytics/savehireinfluencerbycampaign",
      {
        hireInfluenceRecord: finalData,
      }
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setSaving(false);
      return;
    }

    onClose(oOper.lstHiredInflencers);
  };

  useEffect(() => {
    setDataForm({ ...dataRecord });
    setSaving(false);
    setFormErrors({});
  }, [isOpen]);

  return (
    <WiseModal
      hasFooter={false}
      isOpen={isOpen}
      onClose={onClose}
      titleBox={languages.hireInfl}
    >
      <div className="flex flex-col py-2 space-y-2 px-2">
        <BootstrapInput
          fieldName="influencerKey"
          value={dataForm.influencerKey}
          label={languages.influencer}
          fldType="select"
          errorText={formErrors.influencerKey}
          onChange={onChange}
          disabled={saving || dialogStatus !== 0}
          showLabel={true}
          selectOptions={oOptions}
        />

        <BootstrapInput
          fieldName="hiredDate"
          value={appFxs.getDateForInput(dataForm.hiredDate)}
          label={languages.hiredDate}
          fldType="date"
          placeholder={languages.hiredDate.toLowerCase()}
          errorText={formErrors.hiredDate}
          onChange={onChange}
          disabled={saving || dialogStatus !== 0}
          showLabel={true}
        />

        <BootstrapInput
          fieldName="contractPrice"
          value={dataForm.contractPrice}
          label={languages.contractPrice}
          fldType="number"
          placeholder={languages.contractPrice.toLowerCase()}
          errorText={formErrors.contractPrice}
          onChange={onChange}
          disabled={saving || dialogStatus !== 0}
          showLabel={true}
          isNumeric={true}
        />

        <Button
          onClick={() => onSaveRecord()}
          disabled={saving}
          className="w-full py-2 rounded-md"
          color="indigo"
        >
          {saving && <i className="fa fa-spin fa-spinner"></i>}
          {saving
            ? languages.saving
            : dialogStatus === 0
            ? languages.hire
            : languages.close}
        </Button>
      </div>
    </WiseModal>
  );
};


export const MaterialModalDialog = ({
  isOpen,
  dialogTitle,
  children,
  actions,
  onClose,
  width
}: IMaterialModalDialog) => {
  if (!isOpen) return null;
  const nodeElement = document.getElementById("portals")!;

  return ReactDom.createPortal(
    <>
      <div style={{ ...OVERLAY_STYLES, position: "fixed" }}></div>
      <div className="cdk-global-overlay-wrapper">
        <div className="cdk-overlay-0" style={{
          width: width ?? 451
        }}>
          <div className="mat-dialog-container" style={{
          width: width ?? 451
        }}>
            <div className="app-print-system-dlg">
              <div className="dlg-inside">
                {/**Title */}
                <div className="mat-dialog-title">
                  <div className="flex items-center">
                    <div className="flex-1 font-semibold text-xl">
                      {dialogTitle}
                    </div>
                    <button
                      className="cursor-pointer"
                      title={languages.close}
                      onClick={() => onClose()}
                    ><i className="fa fa-times"></i></button>
                  </div>
                </div>
                {/* body */}

                {/* content */}
                <div className="mat-dialog-content">{children}</div>
                {/* actions */}
                <div className="mat-dialog-actions space-x-2 py-2">
                  {actions?.filter(o => {
                    const bVis = o.visible ?? true;

                    return bVis;
                  }).map((o, idx) => {
                    const hasPrimary = o.isPrimary ?? false;
                    const btnDisabled = o.disabled ?? false;
                    const btnBusy = o.busy ?? false;
                    
                    return (
                      <button
                      data-busy={btnBusy}
                      key={idx}
                        className={`mat-focus-indicator mat-raised-button mat-button-base data-[busy=true]:min-w-[90px] ${
                          hasPrimary ? "mat-primary" : ""
                        }`}
                        onClick={() => o.onAction!()}
                        disabled={btnDisabled}
                      >
                        <span className="mat-button-wrapper">
                          {btnBusy && <><i className="fa fa-spin fa-spinner text-black"></i>&nbsp;</> }
                          {!btnBusy && <span>{o.label}</span>}
                        </span>
                        <div className="mat-ripple mat-button-ripple"></div>
                        <div className="mat-button-focus-overlay"></div>
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>,
    nodeElement
  );
};