import React, {
  useEffect,
  useMemo,
  useState,
} from "react";

import getLangs from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import appDecl from "src/utils/declarations";
import { IFormError, IManagtUser } from "src/models/smarttypes";
import { BootstrapInput } from "src/components/WiseModalTextInput";
import {
  ButtonRowAction,
  WiseSmallButton,
} from "src/components/ButtonLinkWithIcon";
import { Link } from "react-router-dom";
import {
  listOfCampaignChannels,
  lstManagementUsers,
} from "src/utils/tempo_data";
import {
  IMonalytCampaign,
  IPostWithTagsRecord,
} from "src/models/monalytics_models";
import NetServices from "src/utils/netservices";
import { DropdownMenu } from "@radix-ui/themes";
import {
  ErrorTableRow,
  LoadingTableRow,
  NoRecordTableRow,
  TableBodyCell,
  TableComponent,
} from "src/components/small_comps";
import { PostRecorderModalWindow } from "src/components/modal_analytics";

const languages = getLangs();
const appFxs = generalFxs();
const cLang = appFxs.getLocalLanguage();
const isEn = cLang === "en";
const todayDate = new Date();
const allMonths = appFxs.wdMonthsLng;

const ManagementDataPage = () => {
  document.title = `${languages.management} | Monalytics`;

  const [connectedUser, setConnectedUser] = useState<IManagtUser | null>(null);

  const afterLogin = (o: IManagtUser) => {
    setConnectedUser(o);
  };

  return (
    <div className="container">
      <div></div>
      {!connectedUser && <UserConnectionComp afterLogin={afterLogin} />}
      {connectedUser && <UserManagementComp connectedUser={connectedUser} />}
    </div>
  );
};

export default ManagementDataPage;

interface IUserConnectionComp {
  afterLogin: (o: IManagtUser) => void;
}
const UserConnectionComp = ({ afterLogin }: IUserConnectionComp) => {
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    userEmail: "",
    userPassword: "",
  });
  const [formError, setFormError] = useState<IFormError>({});
  const changeField = (f: string, v: string | number) => {
    setFormData({ ...formData, [f]: v });
  };

  const onLogin = () => {
    const errors: IFormError = {};

    if (!formData.userEmail || !formData.userEmail) {
      errors.email = languages.fldRequired;
    } else if (!appFxs.isEmailValid(formData.userEmail)) {
      errors.email = languages.emailFormat;
    }

    if (!formData.userPassword || !formData.userPassword) {
      errors.password = languages.fldRequired;
    }

    setFormError(errors);
    if (Object.keys(errors).length > 0) return;

    setSaving(true);

    const foundUser = lstManagementUsers.find((o) => {
      const b =
        o.userEmail.toLowerCase() === formData.userEmail.toLowerCase() &&
        o.userPassword === formData.userPassword;

      return b;
    });

    if (!foundUser) {
      appFxs.showAlert(languages.management, languages.missManagtUser);
      return;
    }

    setTimeout(() => {
      afterLogin(foundUser);
    }, 2000);
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="w-full md:w-[450px] rounded-lg border shadow p-4">
        <div className="flex flex-col">
          <h3 className="text-lg font-semibold py-1 border-b mb-4">
            {languages.connection}
          </h3>
          <div className="mb-4">
            <BootstrapInput
              fldType="text"
              fieldName="userEmail"
              label={languages.email}
              value={formData.userEmail}
              errorText={formError.email}
              showLabel
              placeholder={languages.email}
              disabled={saving}
              onChange={changeField}
            />
          </div>
          <div>
            <BootstrapInput
              fldType="password"
              fieldName="userPassword"
              label={languages.password}
              value={formData.userPassword}
              errorText={formError.password}
              showLabel
              placeholder={languages.password}
              disabled={saving}
              onChange={changeField}
            />
          </div>
          <div className="mt-7">
            <div className="flex items-center justify-between">
              <div>
                <Link
                  className="text-xs lowercase text-blue-500 hover:text-blue-700"
                  to={"/app/dashboard"}
                >
                  <span className="flex items-center space-x-1">
                    <i className="fa fa-cubes"></i>
                    <span>{languages.dashboard}</span>
                  </span>
                </Link>
              </div>
              <div>
                <WiseSmallButton
                  label={languages.login}
                  color="blue"
                  onAction={onLogin}
                  disabled={saving}
                  busy={saving}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ITagElement {
  tag: string;
  campaignKey: string;
  influencerChannels: string[];
}

interface IUserManagementComp {
  connectedUser: IManagtUser;
}

const UserManagementComp = ({ connectedUser }: IUserManagementComp) => {
  const [selectedMonth, setSelectedMonth] = useState(todayDate.getMonth() + 1);
  const [lstCampaigns, setLstCampaigns] = useState<IMonalytCampaign[]>([]);

  const [loading, setLoading] = useState(true);
  const [msgError, setMsgError] = useState<string | null>(null);

  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);

  const sideMenuVisible = useMemo(() => {
    return !menuCollapsed ? true: menuVisible;
  }, [menuCollapsed, menuVisible])

  const lstTagsElements: ITagElement[] = useMemo(() => {
    const lst: ITagElement[] = [];

    lstCampaigns.forEach((oX) => {
      oX.hashtag.forEach((f) => {
        const ff: ITagElement = {
          tag: f,
          campaignKey: oX.campaignKey,
          influencerChannels: oX.influencerChannels,
        };

        lst.push(ff);
      });
    });

    return lst;
  }, [lstCampaigns]);

  const populateData = async () => {
    setLoading(true);
    setMsgError(null);

    const oper = await NetServices.requestGet(
      `monalytics/getlistofcampaignbyperiod/${selectedMonth}`,
      false
    );

    if (!oper.bReturn) {
      setMsgError(oper.msgBody);
      setLoading(false);
      return;
    }

    setLstCampaigns(oper.lstCampaigns!);

    setMsgError(null);
    setLoading(false);
  };


  useEffect(() => {
    populateData();
  }, [selectedMonth]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const oW = entries[0].target.clientWidth;
     if((oW < 768) && !menuCollapsed) setMenuCollapsed(true);
    });

    const resizeElt = document.getElementById("managtapp-bb");
    if (resizeElt) {
      resizeObserver.observe(resizeElt);
    }
  }, []);

  return (
    <div className="p-1 md:p-4 relative" id="managtapp-bb">
      <div className="flex h-screen border">
        <div data-col={menuCollapsed ? 1: 0} 
          data-vis={menuVisible ? 1: 0} 
          className="w-1/3">
          <div className="overflow-y-auto h-full">
            <div className="flex flex-col">
              <div className="p-2 flex justify-end border-b">
                <span className="dropdown">
                  <span
                    className="dropdown-toggle not-drop text-blue-500 cursor-pointer"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="flex items-center space-x-2">
                      <i className="fa fa-calendar"></i>
                      <span>{allMonths[selectedMonth - 1]}</span>
                    </span>
                  </span>
                  <ul className="dropdown-menu">
                    {allMonths.map((o, idx) => (
                      <li key={idx}>
                        <span
                          className="dropdown-item cursor-pointer"
                          onClick={() => setSelectedMonth(idx + 1)}
                        >
                          {o}
                        </span>
                      </li>
                    ))}
                  </ul>
                </span>
              </div>
              <div className="flex-1">
                {loading && (
                  <div className="h-full flex justify-center items-center min-h-[250px]">
                    <div className="px-4 py-3 text-center">
                      <i className="fa fa-spin fa-spinner fa-3x"></i>
                    </div>
                  </div>
                )}

                {!loading && msgError && (
                  <div className="h-full flex justify-center items-center min-h-[250px]">
                    <div className="px-4 py-3 text-center">
                      <h3>{msgError}</h3>
                    </div>
                  </div>
                )}

                {!loading && !msgError && lstCampaigns.length === 0 && (
                  <div className="h-full flex justify-center items-center min-h-[250px]">
                    <div className="px-4 py-3 text-center">
                      <h3>{languages.noCampaignFnd}</h3>
                    </div>
                  </div>
                )}

                {!loading && !msgError && lstCampaigns.length > 0  && <>
                {lstTagsElements.map((o, idx) => (
                  <HashtagCompData key={idx} data={o} />
                ))}
                </>}
              </div>
            </div>
          </div>
        </div>
        <div className="border-l w-2/3">
          <div className="p-2">
            <PostRecordsTable
              period={selectedMonth}
              tags={lstTagsElements.map((o) => o.tag)}
              userEmail={connectedUser.userEmail}
              showMenuBtn={menuCollapsed}
              onShowHideMenu={() => setMenuVisible(!menuVisible)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const HashtagCompData = ({ data }: { data: ITagElement }) => {
  return (
    <div className="flex items-center">
      <div className="flex-1 border-b">
        <div className="p-2">#{data.tag}</div>
      </div>
      <div className="flex justify-center items-center px-3">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <span className="cursor-pointer text-blue-500 hover:text-blue-700">
              <i className="fa fa-ellipsis-h"></i>
            </span>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            {listOfCampaignChannels
              .filter((x) => data.influencerChannels.includes(x.value))
              .map((o, idx) => {
                const iconClass =
                  o.value === "facebook"
                    ? "fa fa-facebook"
                    : o.value === "instagram"
                    ? "fa fa-instagram"
                    : o.value === "linkedin"
                    ? "fa fa-linkedin-square"
                    : o.value === "snapchat"
                    ? "fa fa-snapchat"
                    : o.value === "tiktok"
                    ? "fa fa-ticket"
                    : o.value === "twitter"
                    ? "fa fa-twitter-square"
                    : "fa fa-facebook";
                return (
                  <DropdownMenu.Item
                    className="cursor-pointer"
                    onClick={() => appFxs.openSearchTag(o.value, data.tag)}
                    key={idx}
                  >
                    <div className="flex items-center space-x-2">
                      <i className={iconClass}></i>
                      <span>{o.label}</span>
                    </div>
                  </DropdownMenu.Item>
                );
              })}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
    </div>
  );
};

interface IPostRecordsTable {
  period: number;
  tags: string[];
  userEmail: string;
  showMenuBtn: boolean;
  onShowHideMenu: () => void;
}

const PostRecordsTable = ({ period, tags, userEmail, onShowHideMenu, showMenuBtn }: IPostRecordsTable) => {
  const [loading, setLoading] = useState(true);
  const [msgError, setMsgError] = useState<string | null>(null);
  const [lstPostWithTags, setLstPostWithTags] = useState<IPostWithTagsRecord[]>(
    []
  );

  //#region Dialog
  const [dlgVisible, setDlgVisible] = useState(false);
  const [dlgTitle, setDlgTitle] = useState(languages.newPost);
  const [dlgStatus, setDlgStatus] = useState(0);
  const [dlgRecord, setDlgRecord] = useState<IPostWithTagsRecord | undefined>(
    undefined
  );

  const onCloseDialog = (v: any) => {
    if (v) {
      setLstPostWithTags(v as IPostWithTagsRecord[]);
    }

    setDlgVisible(false);
  };
  const addNewRecord = (o?: IPostWithTagsRecord) => {
    if (o) {
      setDlgRecord({ ...o, postDate: new Date(o.postDate) });
    }

    setDlgStatus(o ? 1 : 0);
    setDlgTitle(o ? languages.editPost : languages.newPost);
    setDlgVisible(true);
  };
  //#endregion

  const onEditRecord = (d: IPostWithTagsRecord) => {
    addNewRecord(d);
  };
  const onDeleteRecord = async (d: IPostWithTagsRecord) => {
    const bAsk = await appFxs.showConfirm(
      languages.delete,
      languages.deleteRecordQst
    );
    if (!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost(
      "socialaccess/savepostrecorddata",
      {
        postRecordData: d,
        toDelete: true,
        monthPeriod: period,
      },
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    setLstPostWithTags(oOper.lstPostWithTags!);
  };

  const populateRecord = async () => {
    setLoading(true);
    setMsgError(null);

    const oOper = await NetServices.requestGet(
      `socialaccess/getpostbyperiods/${period}`
    );

    if (!oOper.bReturn) {
      setMsgError(oOper.msgBody);
      setLoading(false);
    }

    setLstPostWithTags(oOper.lstPostWithTags!);

    setMsgError(null);
    setLoading(false);
  };

  useEffect(() => {
    populateRecord();
  }, [period]);

  return (
    <>
      <PostRecorderModalWindow
        dialogTitle={dlgTitle}
        isOpen={dlgVisible}
        monthPeriod={period}
        userEmail={userEmail}
        windowStatus={dlgStatus}
        recordData={dlgRecord}
        onClose={onCloseDialog}
        listTags={tags}
      />
      <div className="flex flex-col space-y-3">
        <div className="flex items-center mb-3">
          {showMenuBtn && (<button type="button" className="px-3 py-2">
            <i className="fa fa-list-ul"></i>
          </button>)}
          <h3 className="text-lg font-semibold px-2 flex-1">
            {languages.monthlyPosts}
          </h3>
          <button type="button" onClick={() => addNewRecord()} className="px-3 py-2">
            <i className="fa fa-plus"></i>
          </button>
        </div>
        <div className="py-2 px-3">
          <TableComponent
            extraSmall
            hdrBold
            hdrUpperCase
            headerCells={[
              { text: languages.link },
              { text: languages.likes, className: "text-right" },
              { text: languages.dislikes, className: "text-right" },
              { text: languages.comments, className: "text-right" },
              { text: languages.shares, className: "text-right" },
              { text: "", className: "text-right w-[150px]" },
            ]}
          >
            {loading && <LoadingTableRow colSpan={6} />}
            {!loading && msgError && (
              <ErrorTableRow colSpan={6} messageDisplay={msgError} />
            )}
            {!loading && !msgError && lstPostWithTags.length === 0 && (
              <NoRecordTableRow
                colSpan={6}
                messageDisplay={languages.noPostFoundPeriod}
                actionToTake={() => addNewRecord()}
              />
            )}
            {!loading && !msgError && lstPostWithTags.length > 0 && (
              <>
                {lstPostWithTags.map((o, idx) => (
                  <PostTagTableRowRecord
                    key={idx}
                    data={o}
                    onDelete={(d) => onDeleteRecord(d)}
                    onEdit={onEditRecord}
                  />
                ))}
              </>
            )}
          </TableComponent>
        </div>
      </div>
    </>
  );
};

const PostTagTableRowRecord = ({
  data,
  onDelete,
  onEdit,
}: {
  data: IPostWithTagsRecord;
  onEdit: (d: IPostWithTagsRecord) => void;
  onDelete: (d: IPostWithTagsRecord) => void;
}) => {
  return (
    <tr>
      <TableBodyCell>
        <a
          target="_blank"
          href={data.postLink}
          className="text-blue-500"
          rel="noreferrer"
        >
          {appFxs.shortenText(data.postLink, 25, true)}
        </a>
      </TableBodyCell>
      <TableBodyCell align="end">
        {appFxs.formatNumbers(data.likes)}
      </TableBodyCell>
      <TableBodyCell align="end">
        {appFxs.formatNumbers(data.dislikes)}
      </TableBodyCell>
      <TableBodyCell align="end">
        {appFxs.formatNumbers(data.comments)}
      </TableBodyCell>
      <TableBodyCell align="end">
        {appFxs.formatNumbers(data.shares)}
      </TableBodyCell>
      <TableBodyCell className="w-[150px]">
        <div className="flex items-center space-x-2">
          <ButtonRowAction
            icon={<i className="fa fa-pen text-blue-600"></i>}
            onAction={() => onEdit(data)}
          />
          <ButtonRowAction
            icon={<i className="fa fa-trash text-red-600"></i>}
            danger={true}
            onAction={() => onDelete(data)}
          />
        </div>
      </TableBodyCell>
    </tr>
  );
};
