import appDecl from "./declarations";

const getAppLang = (): string => {
  const elt = localStorage.getItem(appDecl.currentLang);
  return elt ?? "en";
};

const retLang = (
  enCont: string,
  frCont: string,
  rwCont: string = ""
): string => {
  const lang = getAppLang();

  return lang === "en"
    ? enCont
    : lang === "fr"
    ? frCont
    : lang === "rw"
    ? rwCont
    : enCont;
};

export default function GetLanguage() {
  return {
    user_editor: retLang("User editor", "Editeur - Utilisateur", ""),
    campaigns: retLang("Campaigns", "Campagnes", ""),
    influencers: retLang("Influencers", "Influenceurs", ""),
    analysis: retLang("Analysis", "Analyse", ""),
    profile: retLang("Profile", "Profile", ""),
    dashboard: retLang("Dashboard", "Tableau de bord", ""),
    country: retLang("Country", "Pays", ""),
    city: retLang("City", "Ville", ""),
    poBox: retLang("PO Box", "Bte Postale", ""),
    appName: retLang("Monalytics", "Monalytics", "Monalytics"),
    goBack: retLang("Go Back", "Revenir en arrière", ""),

    jan_lng: retLang("January", "Janvier", "Mutarama"),
    feb_lng: retLang("February", "Février", "Gashyantare"),
    mar_lng: retLang("March", "Mars", "Werurwe"),
    apr_lng: retLang("April", "Avril", "Mata"),
    may_lng: retLang("May", "Mai", "Gicurasi"),
    jun_lng: retLang("Jun", "Juin", "Kamena"),
    jul_lng: retLang("July", "Juillet", "Nyakanga"),
    aug_lng: retLang("August", "Août", "Kanama"),
    sept_lng: retLang("September", "Septembre", "Nzeri"),
    oct_lng: retLang("October", "Octobre", "Ukwakira"),
    nov_lng: retLang("November", "Novembre", "Ugushyingo"),
    dec_lng: retLang("December", "Decembre", "Ukuboza"),

    jan_shrt: retLang("Jan", "Janr", "Muta"),
    feb_shrt: retLang("Feb", "Fév", "Gashy"),
    mar_shrt: retLang("Mar", "Mars", "Weru"),
    apr_shrt: retLang("Apr", "Avr", "Mata"),
    may_shrt: retLang("May", "Mai", "Gicu"),
    jun_shrt: retLang("Jun", "Juin", "Kame"),
    jul_shrt: retLang("July", "Juil", "Nyaka"),
    aug_shrt: retLang("Aug", "Août", "Kana"),
    sept_shrt: retLang("Sept", "Sept", "Nzeri"),
    oct_shrt: retLang("Oct", "Oct", "Ukwa"),
    nov_shrt: retLang("Nov", "Nov", "Ugus"),
    dec_shrt: retLang("Dec", "Dec", "Uku"),

    monday_lng: retLang("Monday", "Lundi", ""),
    tuesday_lng: retLang("Tuesday", "Mardi", ""),
    wednday_lng: retLang("Wednesday", "Mercredi", ""),
    thursday_lng: retLang("Thursday", "Jeudi", ""),
    friday_lng: retLang("Friday", "Vendredi", ""),
    saturday_lng: retLang("Saturday", "Samedi", ""),
    sunday_lng: retLang("Sunday", "Dimance", ""),

    monday_shrt: retLang("Mon", "Lun", ""),
    tuesday_shrt: retLang("Tue", "Mar", ""),
    wednday_shrt: retLang("Wed", "Mer", ""),
    thursday_shrt: retLang("Thu", "Jeu", ""),
    friday_shrt: retLang("Fri", "Ven", ""),
    saturday_shrt: retLang("Sat", "Sam", ""),
    sunday_shrt: retLang("Sun", "Dim", ""),

    waitServerResp: retLang(
      "Waiting for server response...",
      "Veuillez patienter la reponse du serveur...",
      ""
    ),

    english_m: retLang("English", "Anglais", ""),
    french_m: retLang("French", "Français", ""),
    rwanda_m: retLang("Kinyarwanda", "Kinyarwanda", ""),
    swahili_m: retLang("Swahili", "Swahili", ""),
    lingala_m: retLang("Lingala", "Lingala", ""),
    other_m: retLang("Other language", "Autre langue", ""),
    reload: retLang("Reload", "Recharger", ""),
    cancel: retLang("Cancel", "Annuler", ""),
    ok: retLang("OK", "OK", ""),
    yes: retLang("Yes", "Oui", ""),
    messageDate: retLang("Message date", "Date message", ""),
    networkName: retLang("Network name", "Nom du réseau", ""),
    home: retLang("Home", "Acceuil", ""),
    search: retLang("Search", "Chercher", ""),
    saving: retLang("Saving", "Enregistrement", ""),
    ourPartners: retLang("Our partners", "Nos Partenaires", ""),
    clcickHereToSelect: retLang(
      "Click here to select",
      "Cliquez ici pour sélectionner",
      ""
    ),
    resetPassword: retLang(
      "Reset your password",
      "Modifier votre mot de passe",
      ""
    ),

    emailFormat: retLang(
      "The email format seems wrong.",
      "Le format de l'e-mail semble incorrect.",
      ""
    ),
    passLengthMsg: retLang(
      "Password must be at least 8 characters.",
      "Le mot de passe doit comporter au moins 8 caractères.",
      ""
    ),
    digitchars: retLang("Digit characters", "Caractères numériques", ""),
    passLength: retLang("Password length", "Longeur du mot de passe", ""),
    passValidMsg: retLang(
      "The password strength is too weak to be valid.",
      "Le niveau de sécurité du mot de passe est trop faible pour être valide.",
      ""
    ),
    sameAsPasswordMsg: retLang(
      "You are unable to confirm your password correctly.",
      "Vous n'arrivez pas à confirmer votre mot de passe correctement.",
      ""
    ),

    phonenbrLengthMsg: retLang(
      "The phone number must be 12 caracters long.",
      "Le numéro de téléphone doit être de 12 caractères.",
      ""
    ),
    register: retLang("Register", "S'inscrire", ""),
    emailAddress: retLang("Email address", "Adresse email", ""),
    registerAcctMsg: retLang(
      "Do you want to register your company and the Administrator user account?",
      "Souhaitez-vous enregistrer votre entreprise et le compte utilisateur Administrateur ?",
      ""
    ),
    resetPwMessage: retLang(
      "To reset your password, enter your email below and submit. An email will be sent to you with instructions about how to complete the process.",
      "Pour réinitialiser votre mot de passe, entrez votre e-mail ci-dessous et soumettez. Un e-mail vous sera envoyé avec des instructions sur la façon de terminer le processus.",
      ""
    ),

    resetReqFinalMsg: retLang(
      "We've sent an email to your email inbox containing the link that can help you reset your password. Check your email (or sometimes your junk box) for further steps.",
      "Nous avons envoyé un e-mail dans votre boîte de réception contenant le lien qui peut vous aider à réinitialiser votre mot de passe. Vérifiez votre courrier électronique (ou parfois votre courrier indésirable) pour connaître les étapes ultérieures.",
      ""
    ),

    resetNewPwMsg: retLang(
      "Provide a new password that meets security requirements. Your password must be at least 8 characters long, contain special characters as well as lower and upper case letters. There will be a security bar that will show that your password meets the security rules.",
      "Fournissez un nouveau mot de passe qui répond aux exigences de sécurité. Votre mot de passe doit comporter au moins 8 caractères, contenir des caractères spéciaux ainsi que des lettres minuscules et majuscules. Il y aura une barre de sécurité qui montrera que votre mot de passe répond aux règles de sécurité.",
      ""
    ),

    signInAnyway: retLang("Sign in anyway", "Connectez-vous quand même", ""),
    setNewPw: retLang(
      "Set new password",
      "Definir un nouveau mot de passe",
      ""
    ),
    passwordErrorSec: retLang(
      "This password does not meet the security rules.",
      "Ce mot de passe ne répond pas aux règles de sécurité.",
      ""
    ),
    resetPasswordSuccessMsg: retLang(
      "Your password has been reset with success. Proceed to login screen.",
      "Votre mot de passe a été réinitialisé avec succès. Passez à l'écran de connexion.",
      ""
    ),
    relatedCompanies: retLang("Related companies", "Entreprises liées", ""),
    profileSettings: retLang(
      "Profile settings",
      "Paramètre de profil liées",
      ""
    ),
    changeAppLang: retLang(
      "Change app language",
      "Changer la langue de l'application",
      ""
    ),
    sendInvite: retLang("Send invitation", "Envoyer invitation", ""),
    changeAppLangMsg: retLang(
      "Would you like to change the application main language?",
      "Souhaitez-vous changer la langue principale de l'application ?",
      ""
    ),

    newUser: retLang("New user", "Nouvel utilisateur", ""),
    newUserMsg: retLang(
      "Invite a new user to join your company's team. An email containing a key to join the team will be sent to the new user's email address. This will allow the new user to provide more details about their account.",
      "Invitez un nouvel utilisateur à rejoindre l'équipe de votre entreprise. Un e-mail contenant une clé pour rejoindre l'équipe sera envoyé à l'adresse e-mail du nouvel utilisateur. Cela permettra au nouvel utilisateur de fournir plus de détails sur son compte.",
      ""
    ),

    invite: retLang("Invitation", "Invitation", ""),
    inviteUserQst: retLang(
      "Do you want to resend the invitation email to this user?",
      "Voulez-vous renvoyer l'e-mail d'invitation à cet utilisateur ?",
      ""
    ),
    join: retLang("Join", "Joindre", ""),
    joinTeamMsg: retLang(
      "This operation will help you join your new team on Monalytics. Proceed?",
      "Cette opération vous permettra de rejoindre votre nouvelle équipe sur Monalytics. Continuer ?",
      ""
    ),

    userDetails: retLang("User details", "Détails utilisateur", ""),
    previewUserMsg: retLang(
      "You can preview the selected user details without modifying. In case you want some details changed, kindly contact the account's owner.",
      "Vous pouvez prévisualiser les détails de l'utilisateur sélectionné sans les modifier. Si vous souhaitez modifier certains détails, veuillez contacter le propriétaire du compte.",
      ""
    ),
    listUsers: retLang("List of users", "Liste des utilisateurs", ""),

    logInInstead: retLang(
      "I have account, log in instead",
      "J'ai déja un compte. Veuillez me connecter",
      ""
    ),
    setPasswordWithNew: retLang(
      "Set your password with a new one",
      "Configurez votre mot de passe avec un nouveau",
      ""
    ),
    confirmPassword: retLang(
      "Confirm your password",
      "Confirmer votre mot de passe",
      ""
    ),
    confirmPaswordError: retLang(
      "Your password does not match. Kindly confirm your password.",
      "Votre mot de passe ne correspond pas. Veuillez confirmer votre mot de passe.",
      ""
    ),

    yourEmailHere: retLang("Your email here", "Mettez votre email içi", ""),

    signupfree: retLang("Sign up for free", "En ce moment", ""),
    justNow: retLang("Just now", "En ce moment", ""),
    emotions: retLang("Emotions", "Emotions", ""),
    people: retLang("People", "Peuple", ""),

    error: retLang("Error", "Erreur", ""),
    loading: retLang("Loading", "Chargement", ""),
    login: retLang("Login", "Connection", ""),
    loggin: retLang("Logging In", "Connection", ""),

    password: retLang("Password", "Mot de passe", ""),
    signInTo: retLang("Sign in to", "Connectez-vous à", ""),
    signIn: retLang("Sign in", "Connection", ""),
    signOut: retLang("Sign out", "Se deconnecter", ""),
    signOutMsg: retLang(
      "Do you want to sign out?",
      "Voulez-vous vous déconnecter?",
      ""
    ),
    rememberMe: retLang("Remember me", "Souviens-toi de moi", ""),
    forgotPassWord: retLang("Forgot password?", "Mot de passe oublié?", ""),

    requestDelAcctFinalMsg: retLang(
      "You have requested the deletion of your Monalytics account. We will review your situation within two business days. We will send you a link in your email. By clicking on this link, you will have confirmed the total deletion of your data.",
      "Vous avez demandé la suppression de votre compte Monalytics. Nous examinerons votre situation dans un délai de deux jours ouvrables. Nous vous enverrons un lien dans votre e-mail. En cliquant sur ce lien, vous aurez confirmé la suppression totale de vos données.",
      ""
    ),
    requestDelAcct: retLang("Request deletion", "Demander suppression", ""),
    adminEmail: retLang("Administraor email", "Email administrateur", ""),
    deleteCompaCttDet: retLang(
      "You're about to delete your company account. Make sure you have sufficient rights to run this command.",
      "Vous êtes sur le point de supprimer votre compte d'entreprise. Assurez-vous que vous disposez des droits suffisants pour exécuter cette commande.",
      ""
    ),
    provideUrEmail: retLang(
      "Provide your administrator email",
      "Fournissez votre email d'administrateur",
      ""
    ),
    capitalLetters: retLang("Capital letters", "Lettres capitales", ""),
    smallLetters: retLang("Small letters", "Petites lettes", ""),
    cantShareDev: retLang(
      "This device cannot share files.",
      "Cet appareil ne partage pas les fichiers.",
      ""
    ),
    noTagsYet: retLang("No tags yet", "Pas d'étiquettes"),
    remove: retLang("Remove", "Retirer"),
    featuredImage: retLang("Featured image", "Image en vedette"),
    dialogBoxTitle: retLang(
      "Dialog box title",
      "Titre de la boîte de dialogue"
    ),
    selectFileFromComp: retLang(
      "Select a file from your computer",
      "Sélectionnez un fichier sur votre ordinateur"
    ),
    selectFileFromPexels: retLang(
      "Pick an image from pexels website",
      "Choisissez une image sur le site Web de Pexels"
    ),
    searchFromPexels: retLang(
      "Search from Pexels website",
      "Rechercher sur le site Web Pexels"
    ),
    waitingforPexelsServer: retLang(
      "Waiting for Pexels server",
      "En attente du serveur Pexels"
    ),
    waitingforServerResp: retLang(
      "Waiting for server response",
      "En attente de la reponse du serveur"
    ),
    networkError: retLang("Network error", "Erreur de réseau", ""),
    networkErrorMsg: retLang(
      "There is a network error on this device. Check this issue and try again.",
      "Il y a une erreur réseau sur cet appareil. Vérifiez ce problème et réessayez.",
      ""
    ),
    fldRequired: retLang(
      "This field is required",
      "Ce champ est obligatoire",
      ""
    ),
    codeBeenSent: retLang(
      "A code has been sent to your email",
      "Un code a été envoyé à votre email",
      ""
    ),

    getStarted: retLang("Get started", "Commencer", ""),
    haveAcct: retLang("Have an account?", "Avez-vous un compte ?", ""),
    logInNow: retLang("Log in now", "Connectez-vous maintenant", ""),
    serverWorking: retLang("Server working", "Serveur en fonctionnement", ""),
    code: retLang("Code", "Code", ""),
    confirm: retLang("Confirm", "Confirmez", ""),
    confirmRegistercodes: retLang(
      "Confirm registration code",
      "Confirmez le code d'enregistrement",
      ""
    ),
    companyDetails: retLang("Company details", "Détails de l'entreprise", ""),
    goToAdminDet: retLang("Company details", "Détails de l'entreprise", ""),
    companyEmailHere: retLang(
      "Company email here",
      "Email de l'entreprise",
      ""
    ),
    companyName: retLang("Company name", "Nom de l'entreprise", ""),
    companyNameHere: retLang(
      "Type your company name",
      "Saisir le nom de l'entreprise",
      ""
    ),
    shortName: retLang("Short name", "Nom court", ""),
    shortNameEntr: retLang("Short name here", "Abrégé içi", ""),
    adminUserDetails: retLang(
      "Administrator details",
      "Détails de l'administrateur",
      ""
    ),
    typeFirstName: retLang(
      "Type your first name here",
      "Saisis votre prénom içi",
      ""
    ),
    typeLastName: retLang(
      "Type your last name here",
      "Saisis votre nom içi",
      ""
    ),

    typeFirstNameH: retLang("Type first name here", "Saisis le prénom içi", ""),
    typeLastNameH: retLang("Type last name here", "Saisis le nom içi", ""),

    typeDisplNameFac: retLang(
      "Type your display name here (optional)",
      "Tapez votre nom d'affichage ici (facultatif)",
      ""
    ),
    displayName: retLang("Display name", "Nom d'affichage", ""),
    typePwHere: retLang(
      "Type your password here",
      "Saisissez votre mot de passe içi",
      ""
    ),
    confPwHere: retLang(
      "Confirm your password here",
      "Confirmez votre mot de passe içi",
      ""
    ),

    registerAcct: retLang("Register account", "Créer un compte", ""),

    next: retLang("Next", "Suivant", ""),
    previous: retLang("Previous", "Précédent", ""),

    settings: retLang("Settings", "Paramètres", ""),
    name: retLang("Name", "Nom", ""),
    email: retLang("Email", "Email", ""),
    dob: retLang("DOB", "D. Naiss.", ""),
    settingsMsg: retLang(
      "Configure your software so that it can work according to your preferences.",
      "Paramétrer votre logiciel pour qu'il puisse fonctionner selon vos préférences.",
      ""
    ),
    registerComp: retLang("Register company", "Enregistrer une entreprise", ""),

    notSpecified: retLang("Not specified", "Non spécifié", ""),

    registerCompMsg: retLang(
      `
                You agree to comply with the Monalytics 
                <a class="general_link" target="_blank" href="https://monalytics.rw/legal/privacy">Privacy Policy</a> 
                and by registering you agree that you have sufficient rights to represent your business.<br/> <br/> 
                Let's start with your email.
            `,
      `
                Vous acceptez de vous conformer à la 
                <a class="general_link" target="_blank" href="https://monalytics.rw/legal/privacy">Politique de confidentialité</a> 
                de Monalytics et en vous inscrivant, vous acceptez que vous disposez de droits suffisants pour 
                représenter votre entreprise.<br/> <br/> 
                Commençons par votre email.
            `,
      ""
    ),
    firstName: retLang("First name", "Prénom", ""),
    lastName: retLang("Last name", "Nom", ""),
    birthDate: retLang("Birth date", "Date de naissance", ""),
    phoneNbr: retLang("Phone number", "N° de téléphone", ""),
    myProfile: retLang("My profile", "Mon profile", ""),
    sex: retLang("Sex", "Sexe", ""),
    female: retLang("Female", "Féminin", ""),
    address: retLang("Address", "Adresse", ""),
    specialChars: retLang("Special characters", "Caractères spéciaux", ""),

    users: retLang("Users", "Utilisateurs", ""),

    company: retLang("Company", "Entrprise", ""),
    companySetts: retLang("Company settings", "Paramètres entrprise", ""),
    settingsGlance: retLang(
      "All settings at a glance",
      "Tous les paramètres en un coup d'œil",
      ""
    ),
    save: retLang("Save", "Enregistrer"),
    language: retLang("Language", "Langue", ""),
    year: retLang("Year", "Année", ""),
    refresh: retLang("Refresh", "Recharger", ""),
    itemsPerList: retLang("Items per list", "Éléments par liste", ""),
    savingSettings: retLang(
      "Saving settings",
      "Enregistrement des paramètres",
      ""
    ),
    fileSize: retLang("File size (MB)", "Taille de fichiers (MB)", ""),
    fileSizeMsg: retLang(
      "File size allowed to be uploaded",
      "Taille de fichier autorisée à être téléchargée",
      ""
    ),
    fileSizeErrorMsg: retLang(
      "The selected file has a size that exceeds the allowed size.",
      "Le fichier sélectionné a une taille qui dépasse la taille autorisée.",
      ""
    ),
    deleteRecordQst: retLang(
      "Do you want to delete this record?",
      "Voulez-ous supprimer cet enregistrement?",
      ""
    ),
    noRecFnd: retLang("No record found", "Aucun enregistrement trouvé", ""),
    usersMsg: retLang(
      "You can invite other employees to join you as users with limited rights.",
      "Vous pouvez inviter d'autres employés à vous joindre comme utilisateurs avec des droits limités.",
      ""
    ),
    pngFormatError: retLang(
      "Image format error",
      "Erreur de format d'image",
      ""
    ),
    pngFormatErrorMsg: retLang(
      "This operation requires an image file with a transparent background. Kindly choose a PNG file format.",
      "Cette opération nécessite un fichier image avec un fond transparent. Veuillez choisir un format de fichier PNG.",
      ""
    ),
    fillThisField: retLang("Fill this field", "Remplis ce champ", ""),

    stamp: retLang("Stamp", "Sceau", ""),
    stampMSg: retLang(
      "In case you want to apply your business stamp to documents, you need to set one up.",
      "Si vous souhaitez apposer votre cachet professionnel sur des documents, vous devez en créer un.",
      ""
    ),
    digitalSignature: retLang("Digital signature", "Signature digital", ""),

    applyStampBusDocs: retLang(
      "Set up stamps for business docs",
      "Configurer un tampon pour les documents professionnels",
      ""
    ),
    applySignatureToDocs: retLang(
      "Setup your digital signature",
      "Configurer votre signature digitale",
      ""
    ),
    companyDetailsMsg: retLang(
      "Update your company details",
      "Modifier les données de votre entreprise",
      ""
    ),

    settingsGlanceMsg: retLang(
      "Change the settings of your company and let Archivix work accordingly.",
      "Modifiez les paramètres de votre entreprise et laissez Archivix fonctionner en conséquence.",
      ""
    ),

    management: retLang("Management", "Gestion", ""),

    enableUser: retLang("Enable user", "Activer utilisateur", ""),
    disableUser: retLang("Disable user", "Désactiver utilisateur", ""),
    enableUserMsg: retLang(
      "Do you want to activate this user?",
      "Voulez-vous activer cet utilisateur?",
      ""
    ),
    disableUserMsg: retLang(
      "Do you want to de-activaye this user?",
      "Vulez-vous désactiver cet utilisateur",
      ""
    ),
    addNewUser: retLang("Add new user", "Ajouter nouvel utilisateur", ""),
    editUser: retLang("Edit user", "Modifier utilisateur", ""),
    administrator: retLang("Administrator", "Administrateur", ""),
    joinTeam: retLang("Join team", "Joindre l'équipe", ""),
    confirmAndSetPassword: retLang(
      "Confirm and set password",
      "Confirmer et définir le mot de passe",
      ""
    ),

    activateUser: retLang("Activate user", "Activer utilisateur", ""),
    activateUserMsg: retLang(
      "Do you want to activate the selected user?",
      "Voulez-vous activer l'utilisateur sélectionné?",
      ""
    ),

    deactivateUser: retLang("De-Activate user", "Désactiver utilisateur", ""),
    deactivateUserMsg: retLang(
      "Do you want to de-activate the selected user?",
      "Voulez-vous désactiver l'utilisateur sélectionné?",
      ""
    ),
    delete: retLang("Delete", "Supprimer", ""),

    male: retLang("Male", "Masculin", ""),

    sent: retLang("Sent", "Envoyés", ""),
    deleted: retLang("Deleted", "Supprimés", ""),
    preview: retLang("Preview", "Visualisez", ""),
    markAsRead: retLang("Mark as read", "Marquer comme lu", ""),
    markAsUnread: retLang("Mark as unread", "Marquer comme non lu", ""),

    other: retLang("Other", "Autre", ""),
    activate: retLang("Activate", "Activer", ""),
    deactivate: retLang("Disable", "Désactiver", ""),
    activate_qst: retLang(
      "Would you like to activate this user?",
      "Voudriez-vous activer cet utilisateur?",
      ""
    ),
    deactivate_qst: retLang(
      "Would you like to Disable this user?",
      "Voudriez-vous désactiver cet utilisateur?",
      ""
    ),
    activated: retLang("Activated", "Activé", ""),
    deactivated: retLang("Disabled", "Désactivé", ""),
    myProfileMsg: retLang(
      "Please identify yourself so that other collaborators can identify you.",
      "Veuillez vous identifier pour que les autres les autres collaborateurs puissent vous identifier.",
      ""
    ),

    or: retLang("Or", "Ou", ""),
    dtHaveAcct: retLang(
      "Don't have account yet?",
      "Vous n'avez pas encore de compte ?",
      ""
    ),

    setNewPassword: retLang(
      "Set new password",
      "Definir un nouveau mot de passe",
      ""
    ),
    newPassword: retLang("New password", "Nouveau mot de passe", ""),
    setPassword: retLang("Set password", "Definir mot de passe", ""),

    emailEx: retLang("you@example.com", "vous@exemple.com", ""),
    reset: retLang("Reset", "Réinitialiser", ""),
    listCampaigns: retLang("List of campaigns", "Liste des campagnes", ""),
    title: retLang("Title", "Titre", ""),
    date: retLang("Date", "Date", ""),
    start: retLang("Start", "Debut", ""),
    end: retLang("End", "Fin", ""),
    hashtag: retLang("Hashtag", "Mot-clic", ""),
    errorLoadData: retLang(
      "Error loading data",
      "Erreur lors du chargement des données",
      ""
    ),
    add: retLang("Add", "Ajouter", ""),
    print: retLang("Print", "Imprimer", ""),
    newCampaign: retLang("New campaign", "Nouvelle campagne", ""),
    description: retLang("Description", "Description", ""),
    startCampaign: retLang("Start campaign", "Démarrer campagne", ""),
    inflChannelTarget: retLang(
      "Influencers channel target",
      "Cible du canal des influenceurs",
      ""
    ),
    dateFldConfigError: retLang(
      "Date field configuration error.",
      "Erreur de configuration du champ de date",
      ""
    ),
    logo: retLang("Logo", "Logo", ""),
    wall: retLang("Wall", "Mur", ""),
    uploadLogo: retLang("Upload logo image", "Télécharger l'image du logo", ""),
    uploadWall: retLang("Upload wall image", "Télécharger l'image du mur", ""),

    removeLogo: retLang("Remove logo image", "Supprimer l'image du logo", ""),
    removeWall: retLang("Remove wall image", "Supprimer l'image du mur", ""),
    campaignImgError: retLang(
      "At least the campaign logo image must be provided.",
      "Au moins l'image du logo de la campagne doit être fournie.",
      ""
    ),
    snapchatTagFtrErr: retLang(
      "Snapchat has not yet implemented this feature. Check later.",
      "Snapchat n'a pas encore implémenté cette fonctionnalité. Vérifiez plus tard.",
      ""
    ),
    seeHiredInfluencers: retLang(
      "See hired influencers",
      "Voir les influenceurs embauchés",
      ""
    ),
    postedDate: retLang("Posted date", "Date de publication", ""),
    postedBy: retLang("Posted by", "Publié par", ""),
    period: retLang("Period", "Période", ""),
    inactive: retLang("Inactive", "Inactif", ""),
    active: retLang("Active", "Actif", ""),
    status: retLang("Status", "Statut", ""),
    budget: retLang("Budget", "Budget", ""),
    currency: retLang("Currency", "Devise", ""),
    hireInfl: retLang("Hire influencer", "Engager un influenceur", ""),
    influencer: retLang("Influencer", "Influenceur", ""),
    noCampInfl: retLang(
      "No influencers have been hired for this campaign yet.",
      "Aucun influenceur n'a encore été embauché pour cette campagne.",
      ""
    ),
    hiredDate: retLang("Hired date", "Date de recrutement", ""),
    contractPrice: retLang("Contract price", "Prix du contrat", ""),
    hire: retLang("Hire", "Embaucher", ""),
    priceInsuff: retLang(
      "The price is insufficient. The amount must be greater than zero.",
      "Le prix est insuffisant. Le montant doit être supérieur à zéro.",
      ""
    ),
    exceedBudget: retLang(
      "Can we exceed the budget?",
      "Peut-on dépasser le budget?",
      ""
    ),
    close: retLang("Close", "Fermer", ""),
    edit: retLang("Edit", "Modifier", ""),
    hired: retLang("Hired", "Embauché", ""),
    hiredInfl: retLang("Hired influencers", "Influenceurs embauchés", ""),
    mismatchRecord: retLang(
      "Mismatching record",
      "Enregistrement non concordant",
      ""
    ),
    mismatchRecordMsg: retLang(
      "No records matching the criteria were found. Please try again.",
      "Aucun enregistrement correspondant aux critères n'a été trouvé. Veuillez réessayer.",
      ""
    ),
    send: retLang("Send", "Envoyer", ""),
    recipients: retLang("Recipients", "Destinataires", ""),
    infl: retLang("Infl", "Infl", ""),
    send_sms: retLang("Send SMS", "Envoyer SMS", ""),
    make_call: retLang("Make call", "Passer un appel", ""),
    financeDocts: retLang("Finance documents", "Documents financiers", ""),
    notes: retLang("Notes", "Notes", ""),
    emails: retLang("Emails", "Emails", ""),
    files: retLang("Files", "Fichiers", ""),
    todo: retLang("ToDo", "A Faire", ""),
    events: retLang("Events", "Événements", ""),
    phone: retLang("Phone", "Téléphone", ""),
    sms: retLang("SMS", "SMS", ""),
    addNewNote: retLang("Add new note", "Ajouter une nouvelle note", ""),
    addNewEmail: retLang("Add new email", "Ajouter un nouvel email", ""),
    sendEmail: retLang("Send email", "Envoyer email", ""),
    noNotePosted: retLang(
      "No note has been posted yet.",
      "Aucune note n'a encore été publiée.",
      ""
    ),
    noEmailPosted: retLang(
      "No email has been sent yet.",
      "Aucun email n'a encore été envoyé.",
      ""
    ),
    noCallPosted: retLang(
      "No phone call has been sent yet.",
      "Aucun appel n'a encore été envoyé.",
      ""
    ),
    noTodoPosted: retLang(
      "No ToDos have been recorded yet.",
      "Aucune tâche n'a encore été enregistrée.",
      ""
    ),
    noEventPosted: retLang(
      "No events have been recorded yet.",
      "Aucun événement n'a encore été enregistré.",
      ""
    ),
    noSmsPosted: retLang(
      "No SMS have been sent yet.",
      "Aucun SMS n'a encore été envoyé.",
      ""
    ),
    noFilePosted: retLang(
      "No files have been uploaded yet.",
      "Aucun fichier n'a encore été téléchargé.",
      ""
    ),
    notSent: retLang("Not sent", "Non envoyé", ""),
    note: retLang("Note", "Note", ""),
    subject: retLang("Subject", "Objet", ""),
    otherRecips: retLang("Other recipients", "Autres destinataires", ""),
    callDetails: retLang("Call details", "Détails appel", ""),
    logDate: retLang("Log date", "Date du journal", ""),
    callDate: retLang("Call date", "Date de l'appel", ""),
    sendSms: retLang("Send SMS", "Envoyer SMS", ""),
    addPhoneLog: retLang("Add phone log", "Ajouter appel téléphonique", ""),
    addNewPhoneLog: retLang(
      "Add new phone log",
      "Ajouter un nouvel appel téléph.",
      ""
    ),
    assignedTo: retLang("Assigned To", "Attribué à", ""),
    dueDate: retLang("Due date", "Échéance", ""),
    addTodo: retLang("Add ToDo", "A Faire", ""),
    uploadFile: retLang("Upload a file", "Télécharger un fichier", ""),
    download: retLang("Download", "Télécharger", ""),

    done: retLang("Done", "Terminé", ""),
    pastdue: retLang("Past due", "Echu", ""),
    upcoming: retLang("Upcoming", "Prochain", ""),
    eventName: retLang("Event name", "Nom de l'événement", ""),
    location: retLang("Location", "Emplacement", ""),
    allDayEvt: retLang("All day event", "Journée entière", ""),
    anniversary: retLang("Anniversary", "Anniversaire", ""),
    addEvent: retLang("Add event", "Ajouter événement", ""),
    uploaded: retLang("Uploaded", "Téléchargé", ""),
    smsSetts: retLang("SMS Settings", "Paramètres SMS", ""),
    smsAccts: retLang("SMS Accounts", "Comptes Sms", ""),
    payment: retLang("Payment", "Paiement", ""),
    invoice: retLang("Invoice", "Facture", ""),
    expense: retLang("Expense", "Dépense", ""),
    smsAcct: retLang("SMS Account", "Compte Sms", ""),
    smsSettConnectMsg: retLang(
      "Monalytics can connect to your sms account and send sms to your contacts. Once connected, you'll be able to interact with contacts right on each individual contact page.",
      "Monalytics peut se connecter à votre compte SMS et envoyer des SMS à vos contacts. Une fois connecté, vous pourrez interagir avec les contacts directement sur chaque page de contact individuelle.",
      ""
    ),
    addAccount: retLang("Add account", "Ajouter compte", ""),
    editSmsAcct: retLang("Edit SMS Account", "Modifier compte Sms", ""),
    newSmsAcct: retLang("New SMS Account", "Nouveau compte Sms", ""),
    noAcctSmsSettRecord: retLang(
      "No SMS account has been recorded yet.",
      "Aucun compte SMS n'a encore été enregistré.",
      ""
    ),
    acctName: retLang("Account Name", "Nom Compte", ""),
    lastSaved: retLang("Last saved", "Dernier enregistrement", ""),
    smsDate: retLang("Sms Date", "Date SMS", ""),
    message: retLang("Message", "Message", ""),
    sending: retLang("Sending", "Envoi", ""),
    notBilled: retLang("Not billed", "Non facturé", ""),

    invoiceNbr: retLang("Invoice Nbr", "N° Facture", ""),
    expenseNbr: retLang("Expense Nbr", "N° Dépense", ""),
    paytNbr: retLang("Payt Nbr", "N° Paiement", ""),

    invoiceDate: retLang("Invoice Date", "Date Facture", ""),
    expenseDate: retLang("Expense Date", "Date Dépense", ""),
    paytDate: retLang("Payt Date", "Date Paiement", ""),

    invoiceAmt: retLang("Invoice Amt.", "Mt. Facture", ""),
    expenseAmt: retLang("Expense Amt.", "Mt. Dépense", ""),
    paytAmt: retLang("Payt Amt.", "Mt. Paiement", ""),

    invoiceRef: retLang("Invoice Ref.", "Réf. Facture", ""),
    expenseRef: retLang("Expense Ref.", "Réf. Dépense", ""),
    paytRef: retLang("Payt Ref.", "Réf. Paiement", ""),
    prodItem: retLang("Product/Item", "Produit/Article", ""),
    billed: retLang("Billed", "Facturé", ""),

    newPayt: retLang("New payment", "Nouveau paiement", ""),
    newInvoice: retLang("New invoice", "Nouvelle facture", ""),
    newExpense: retLang("New expense", "Nouvelle dépense", ""),

    editPayt: retLang("Edit payment", "Modifier paiement", ""),
    editInvoice: retLang("Edit invoice", "Modifier facture", ""),
    editExpense: retLang("Edit expense", "Modifier dépense", ""),
    sendNoteDet: retLang(
      "Send a note for more details",
      "Envoyez une note pour plus de détails",
      ""
    ),
    makeCallDet: retLang(
      "Record a phone log related to this operation",
      "Enregistrer un journal téléphonique lié à cette opération",
      ""
    ),
    sendEmailDet: retLang(
      "Send email to the influencer",
      "Envoyer un e-mail à l'influenceur",
      ""
    ),
    sendSmsDet: retLang(
      "Send an SMS to the influencer",
      "Envoyer un SMS à l'influenceur",
      ""
    ),
    uploadFileDet: retLang(
      "Upload files to share",
      "Télécharger des fichiers à partager",
      ""
    ),
    invoices: retLang("Invoices", "Factures", ""),
    payments: retLang("Payments", "Paiements", ""),
    expenses: retLang("Expenses", "Dépenses", ""),
    createIncomeG: retLang("Generated incomes", "Revenus générés", ""),
    todoDetail: retLang(
      "Keep a list of things that need to get done",
      "Gardez une liste des choses à faire",
      ""
    ),
    eventDetail: retLang(
      "Schedule meetings and appointments",
      "Planifier des réunions et des rendez-vous",
      ""
    ),
    paytsDetail: retLang(
      "Record payments made to the influencer",
      "Enregistrer les paiements effectués à l'influenceur",
      ""
    ),
    expenseDetail: retLang(
      "Record expenses made to this campaign",
      "Enregistrer les dépenses effectuées pour cette campagne",
      ""
    ),
    noFinanceDoctMsg: retLang(
      "No finance documents has been found.",
      "Aucun document financier n'a été trouvé.",
      ""
    ),
    campaign: retLang("Campaign", "Campagne", ""),
    byCampaign: retLang("By campaign", "Par campagne", ""),
    byHashtag: retLang("By hashtag", "Par mot-dèse", ""),
    noCampSelected: retLang(
      "No campaign selected",
      "Aucune campagne sélectionnée",
      ""
    ),
    noSelection: retLang("No selection", "Aucune sélection", ""),
    noTag: retLang("No tag", "Aucune mot-dièse", ""),
    noCountry: retLang("No country", "Pas de pays", ""),
    launchAnalys: retLang("Start analysis", "Lancer l'analyse", ""),
    tagActivity: retLang("Tag activity", "Activité du Mot-clé", ""),
    channel: retLang("Channel", "Chaîne", ""),
    channels: retLang("Channels", "Chaînes", ""),
    networks: retLang("Networks", "Réseaux", ""),
    mentions: retLang("Mentions", "Mentions", ""),
    hits: retLang("Hits", "Clics", ""),
    strongestHashTags: retLang(
      "Strongest hashtags",
      "Les mot-clés les plus forts",
      ""
    ),
    hashtags: retLang("Hashtags", "Mot-clés", ""),
    top3: retLang("Top 3", "Les 3 premiers", ""),
    highBudgCamp: retLang(
      "High budgeted campaigns",
      "Campagnes à gros budget",
      ""
    ),
    growth: retLang("Growth", "Croissance", ""),
    connection: retLang("Connection", "Connection", ""),
    link: retLang("Link", "Lien", ""),
    likes: retLang("Likes", "J'aime", ""),
    dislikes: retLang("Dislikes", "N'aime pas", ""),
    comments: retLang("Comments", "Commentaires", ""),
    shares: retLang("Shares", "Partages", ""),
    noPostFoundPeriod: retLang(
      "No social network post found for this selected period.",
      "Aucune publication sur les réseaux sociaux n'a été trouvée pour cette période sélectionnée.",
      ""
    ),
    missManagtUser: retLang(
      "None of the management users matches your credentials. Try again with a new credential.",
      "Aucun des utilisateurs de gestion ne correspond à vos informations d'identification. Réessayez avec de nouvelles informations d'identification.",
      ""
    ),
    newPost: retLang("New post", "Nouvelle publication", ""),
    editPost: retLang("Edit post", "Modifier publication", ""),
    monthlyPosts: retLang("Monthly posts", "Publications mensuelles", ""),
    postOwner: retLang("Post owner", "Propriétaire publication", ""),
    contentCreator: retLang("Content creator", "Créateur de contenu", ""),
    tags: retLang("Tags", "Mots clés", ""),
    positive: retLang("Positive", "Positif", ""),
    negative: retLang("Negative", "Négatif", ""),
    websiteFormatError: retLang(
      "Invalid URL format. Please check and try again.",
      "Format d'URL non valide. Veuillez vérifier et réessayer.",
      ""
    ),
    tagsIncompatible: retLang(
      "None of the tags can be found in the selected tags that we can find in the campaign tags.",
      "Aucune des balises ne peut être trouvée dans les balises sélectionnées que nous pouvons trouver dans les balises de campagne.",
      ""
    ),
    postDate: retLang("Post date", "Date publication", ""),
    noCampaignFnd: retLang(
      "No campaigns were found for the selected period. Therefore, there is no reference hashtag.",
      "Aucune campagne n'a été trouvée pour la période sélectionnée. Il n'y a donc aucun hashtag de référen",
      ""
    ),
    from: retLang("From", "De", ""),
    to: retLang("To", "A", ""),
    
  };
}
