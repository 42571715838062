import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AcctLoginPage from './pages/auth/AcctLoginPage';
import AcctRegisterPage from './pages/auth/AcctRegisterPage';
import AcctForgotPw from './pages/auth/AcctForgotPw';
import AcctResetPwPage from './pages/auth/AcctResetPwPage';
import AcctJoinTeamPage from './pages/auth/AcctJoinTeamPage';
import MyProfilePage from './pages/app/MyProfilePage';
import ProfilePage from './pages/app/ProfilePage';
import InfluencersPage from './pages/app/InfluencersPage';
import CampaignPage from './pages/app/CampaignPage';
import AnalysisPage from './pages/app/AnalysisPage';
import DashboardPage from './pages/app/DashboardPage';
import AccountLayout from './pages/layout/AccountLayout';
import AppLayout from './pages/layout/AppLayout';
import LocalSettsPAge from './pages/app/settings/LocalSettsPAge';
import ListUsersPage from './pages/app/settings/ListUsersPage';
import CompanySettingsPage from './pages/app/settings/CompanySettingsPage';
import SettingsLayout from './pages/layout/SettingsLayout';
import UserProfilePage from './pages/app/settings/UserProfilePage';
import PreviewUserPage from './pages/app/settings/PreviewUserPage';
import NewUserPage from './pages/app/settings/NewUserPage';
import CampaignNewPage from './pages/app/CampaignNewPage';
import CampaignEditorPage from './pages/app/CampaignEditorPage';
import CampaignInfluencersPage from './pages/app/CampaignInfluencersPage';
import CampaignHireInflOper from './pages/app/CampaignHireInflOper';
import SmsSettingsPage from './pages/app/settings/SmsSettingsPage';
import ManagementDataPage from './pages/managt/ManagementDataPage';

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />

      <Route path="/account" element={<AccountLayout />}>
        <Route index element={<AcctLoginPage />} />
        <Route path="login" element={<AcctLoginPage />} />
        <Route path="register" element={<AcctRegisterPage />} />
        <Route path="forgotpw/:forgotPwEmail" element={<AcctForgotPw />} />
        <Route path="resetpassword/:resetKey" element={<AcctResetPwPage />} />
        <Route path="jointeam/:joinKey" element={<AcctJoinTeamPage />} />
      </Route>


      <Route path="/app" element={<AppLayout />}>
        <Route index element={<DashboardPage />} />
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="analysis" element={<AnalysisPage />} />

        <Route path="campaign" element={<CampaignPage />} />
        <Route path="campaign/newcampaign/:periodMonth" element={<CampaignNewPage />} />
        <Route path="campaign/editor/:campaignKey" element={<CampaignEditorPage />} />
        <Route path="campaign/influencers/:campaignKey" element={<CampaignInfluencersPage />} />
        <Route path="campaign/management/:operationKey" element={<CampaignHireInflOper />} />

        <Route path="influencers" element={<InfluencersPage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="myprofile" element={<MyProfilePage />} />

        <Route path="/app/settings" element={<SettingsLayout />}>
          <Route index element={<LocalSettsPAge />} />
          <Route path="settings" element={<LocalSettsPAge />} />
          <Route path="company" element={<CompanySettingsPage />} />
          <Route path="myprofile" element={<UserProfilePage />} />
          <Route path="smssetts" element={<SmsSettingsPage />} />
          

          <Route path="users" element={<ListUsersPage />} />
          <Route path="users/preview/:userKey" element={<PreviewUserPage  />} />
          <Route path="users/newuser" element={<NewUserPage  />} />
        </Route>        
      </Route>

      <Route path="managementdata" element={<ManagementDataPage />} />
    </Routes>
  );
}

export default App;
