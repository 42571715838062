import { useEffect, useState } from "react";
import { MaterialModalDialog } from "./modal_comps";
import { IFormError, IMaterialButton } from "src/models/smarttypes";

import getLangs from "src/utils/languages";
import getFxs from "src/utils/general_fx";
import { IPostWithTagsRecord } from "src/models/monalytics_models";
import NetServices from "src/utils/netservices";
import { BootstrapInput } from "./WiseModalTextInput";
import { listOfCampaignChannels } from "src/utils/tempo_data";
import WiseTagsInput from "./WiseTagsInput";

const languages = getLangs();
const appFxs = getFxs();

const defaultPostRecord: IPostWithTagsRecord = {
  channel: "facebook",
  comments: 0,
  dislikes: 0,
  likes: 0,
  period: 1,
  postDate: new Date(),
  postLink: "",
  postUserName: "",
  recordKey: "",
  shares: 0,
  tags: [],
  userEmail: "",
  year: 2024,
  postKey: "",
};

interface IPostRecorderModal {
  isOpen: boolean;
  dialogTitle: string;
  onClose: (v: any) => void;
  windowStatus: number;
  recordData?: IPostWithTagsRecord;
  userEmail: string;
  monthPeriod: number;
  listTags: string[];
}

export const PostRecorderModalWindow = ({
  isOpen,
  dialogTitle,
  onClose,
  windowStatus,
  recordData,
  monthPeriod,
  userEmail,
  listTags,
}: IPostRecorderModal) => {
  const [saving, setSaving] = useState(false);
  const [formData, setFomrData] = useState<IPostWithTagsRecord>(
    recordData
      ? { ...recordData }
      : {
          ...defaultPostRecord,
          period: monthPeriod,
          userEmail: userEmail,
        }
  );
  const [formErrors, setFormErrors] = useState<IFormError>({});

  const onChangeFldVal = (f: string, v: string | number) => {
    if (f === "postDate") {
      setFomrData({ ...formData, [f]: new Date(v) });
    } else {
      setFomrData({ ...formData, [f]: v });
    }
  };
  const onTagsChange = (f: string, v: string[]) => {
    setFomrData({ ...formData, [f]: v });
  };

  const onCloseDlg = () => {
    onClose(null);
  };
  const onSaveRecord = async () => {
    const errors: IFormError = {};

    if (!formData.postLink || formData.postLink.length === 0) {
      errors.link = languages.fldRequired;
    } else if (!appFxs.isValidUrl(formData.postLink)) {
      errors.link = languages.websiteFormatError;
    }
    if (formData.likes.toString().length === 0) {
      errors.likes = languages.fldRequired;
    }
    if (formData.dislikes.toString().length === 0) {
      errors.dislikes = languages.fldRequired;
    }
    if (formData.comments.toString().length === 0) {
      errors.comments = languages.fldRequired;
    }
    if (formData.shares.toString().length === 0) {
      errors.shares = languages.fldRequired;
    }
    if (formData.tags.length === 0) {
      errors.tags = languages.fldRequired;
    } else if (!formData.tags.some((oItem) => listTags.includes(oItem))) {
      errors.tags = languages.tagsIncompatible;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setSaving(true);

    const oOper = await NetServices.requestPost(
      "socialaccess/savepostrecorddata",
      {
        postRecordData: formData,
        toDelete: false,
        monthPeriod: monthPeriod,
      }
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setSaving(false);
      return;
    }

    onClose(oOper.lstPostWithTags!);
  };

  const actions: IMaterialButton[] = [
    { label: languages.cancel, disabled: saving, onAction: onCloseDlg },
    {
      label: languages.save,
      disabled: saving,
      onAction: () => onSaveRecord(),
      busy: saving,
      isPrimary: true,
    },
  ];

  useEffect(() => {
    setFomrData(
      recordData
        ? { ...recordData }
        : {
            ...defaultPostRecord,
            period: monthPeriod,
            userEmail: userEmail,
          }
    );
    setFormErrors({});
    setSaving(false);
  }, [isOpen]);

  return (
    <MaterialModalDialog
      dialogTitle={dialogTitle}
      isOpen={isOpen}
      onClose={onClose}
      actions={actions}
    >
      <div className="flex flex-col py-2 space-y-3">
        {/* channel */}
        <div>
          <BootstrapInput
            fieldName="channel"
            value={formData.channel}
            label={languages.channel}
            fldType="select"
            errorText={formErrors.channel}
            onChange={onChangeFldVal}
            disabled={saving || windowStatus === 1}
            showLabel={true}
            placeholder={languages.channel}
            selectOptions={listOfCampaignChannels.map((o) => {
              return {
                label: o.label,
                value: o.value,
              };
            })}
          />
        </div>

        {/* postLink */}
        <div>
          <BootstrapInput
            fieldName="postLink"
            value={formData.postLink}
            label={languages.link}
            fldType="url"
            errorText={formErrors.link}
            onChange={onChangeFldVal}
            disabled={saving || windowStatus === 1}
            showLabel={true}
            placeholder={languages.link}
            maxLength={500}
          />
        </div>

        {/* postLink */}
        <div>
          <BootstrapInput
            fieldName="postDate"
            value={appFxs.getDateForInput(formData.postDate)}
            label={languages.postDate}
            fldType="date"
            errorText={formErrors.postDate}
            onChange={onChangeFldVal}
            disabled={saving || windowStatus === 1}
            showLabel={true}
            placeholder={languages.postDate}
          />
        </div>

        {/* postUserName */}
        <div>
          <BootstrapInput
            fieldName="postUserName"
            value={formData.postUserName}
            label={languages.contentCreator}
            fldType="text"
            errorText={formErrors.postUserName}
            onChange={onChangeFldVal}
            disabled={saving || windowStatus === 1}
            showLabel={true}
            placeholder={languages.contentCreator}
          />
        </div>

        <div className="flex items-center flex-wrap justify-between">
          <div className="w-full md:w-[49%]">
            <div>
              <BootstrapInput
                fieldName="likes"
                value={formData.likes}
                label={languages.likes}
                fldType="number"
                errorText={formErrors.likes}
                onChange={onChangeFldVal}
                disabled={saving}
                showLabel={true}
                placeholder={languages.likes}
                isNumeric
              />
            </div>
          </div>
          <div className="w-full md:w-[49%]">
            <div>
              <BootstrapInput
                fieldName="dislikes"
                value={formData.dislikes}
                label={languages.dislikes}
                fldType="number"
                errorText={formErrors.dislikes}
                onChange={onChangeFldVal}
                disabled={saving}
                showLabel={true}
                placeholder={languages.dislikes}
                isNumeric
              />
            </div>
          </div>
        </div>

        <div className="flex items-center flex-wrap justify-between">
          <div className="w-full md:w-[49%]">
            <div>
              <BootstrapInput
                fieldName="shares"
                value={formData.shares}
                label={languages.shares}
                fldType="number"
                errorText={formErrors.shares}
                onChange={onChangeFldVal}
                disabled={saving}
                showLabel={true}
                placeholder={languages.shares}
                isNumeric
              />
            </div>
          </div>
          <div className="w-full md:w-[49%]">
            <div>
              <BootstrapInput
                fieldName="comments"
                value={formData.comments}
                label={languages.comments}
                fldType="number"
                errorText={formErrors.comments}
                onChange={onChangeFldVal}
                disabled={saving}
                showLabel={true}
                placeholder={languages.comments}
                isNumeric
              />
            </div>
          </div>
        </div>

        <div>
          <WiseTagsInput
            fieldName="tags"
            label={languages.tags}
            errorText={formErrors.tags}
            value={formData.tags}
            disabled={saving}
            placeholder={languages.tags}
            showIcon={true}
            onChange={onTagsChange}
            spaceNotAllowed={true}
            showLabel={true}
          />
        </div>
      </div>
    </MaterialModalDialog>
  );
};
