const COLORS = {
    primary: '#027e6f',
    airForceBlue: '#5d8aa8',
    aliceBlue: '#f0f8ff',
    amber: '#ffbf00',
    americanRose: '#ff033e',
    androidGreen: '#a4c639',
    ao: '#008000',
    appleGreen: '#8db600',
    armyGreen: '#4b5320',
    awesome: '#ff2052',
    azure: '#007fff',
    babyBlue: '#89cff0',
    babyBlueEyes: '#a1caf1',
    ballBlue: '#21abcd',
    banaYellow: '#ffe135',
    beige: '#f5f5dc',
    bostonUnivRed: '#cc0000',
}

export default COLORS;